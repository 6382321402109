import {Link} from "react-router-dom";
import React from "react";

import useWindowWidth, {LG_SCREEN_WIDTH_PX} from "./hooks/useWindowWidth";
import useGetLatestSummaryInvoicePDF from "../homepage/hooks/useGetLatestSummaryInvoicePDF";
import {GlobalContext} from "../globalContext/Context";

function PayAndDownloadCurrentInvoiceButtonGroup() {
    const windowWidth = useWindowWidth();
    const latestPDF = useGetLatestSummaryInvoicePDF(windowWidth);
    const {chargeTotal} = GlobalContext();

    const handleOpenPDF = async() => {
        const pdf = await latestPDF.fetchPDF();
        if(pdf !== null) {
            window.open(pdf, '_blank');
        }
    }


    return (
        <div className="row mb-4">
            <div className="col-12 col-lg-6 pb-4 pb-lg-0 p-0">
                <div style={windowWidth > LG_SCREEN_WIDTH_PX ? {paddingRight: '0.5rem'} : {}}>
                    <Link to="/pay-my-bill">
                        <button className="payBtn" disabled={Number(chargeTotal) === 0} type="button">
                                <h5 className="" style={{
                                    fontSize: '20px',
                                }}> Pay </h5>
                        </button>
                    </Link>
                </div>
            </div>
            <div className="col-12 col-lg-6 p-0">
                <div style={windowWidth > LG_SCREEN_WIDTH_PX ? {paddingLeft: '0.5rem'} : {}}>
                    <button className="downloadBtn" onClick={handleOpenPDF} type="button">
                        <h5 className="" style={{fontSize: '20px', color: '#23A9E1'}}> View Latest
                            Invoice Summary </h5>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default React.memo(PayAndDownloadCurrentInvoiceButtonGroup);