import {ServiceCallsComponent} from "./interfaces/sseInterfaces";
import React from "react";

function ServiceCalls(props: ServiceCallsComponent) {
    return (
        <>
            <div className="w-100" style={{overflowX: 'auto'}}>
                <table className="table table-borderless table-responsive">
                    <thead className="justify-content-center">
                    <tr className="align-content-center">
                        <th className="text-start d-table-cell" style={{width: '20%'}}>Date</th>
                        <th className="d-none d-md-table-cell" style={{width: '20%'}}>Description
                        </th>
                        <th className="d-md-none d-table-cell" style={{width: '20%'}}>Desc
                        </th>
                        <th className="d-none d-md-table-cell" style={{width: '15%'}}>Quantity
                        </th>
                        <th className="d-md-none d-table-cell" style={{width: '15%'}}>Qty
                        </th>
                        <th className="d-table-cell" style={{width: 'auto'}}>Rate</th>
                        <th className="d-table-cell text-end" style={{width: 'auto'}}>Charge</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.serviceCalls.map((services) => {

                        return (<>
                                <tr>
                                    <td className="d-table-cell">{new Date(services.DispatchDate).toLocaleDateString()}</td>
                                    <td className="d-table-cell" style={{width: '20%'}}>Labor (RT)</td>
                                    <td className="d-table-cell" style={{width: '15%'}}>{Number(services.LaborHours).toFixed(2)}</td>
                                    <td className="d-table-cell" style={{width: '15%'}}>{props.formatter.format(Number(services.LaborRate))}</td>
                                    <td className="d-table-cell text-end" style={{width: 'auto'}}>{props.formatter.format(Number(services.LaborHours) * Number(services.LaborRate))}</td>
                                </tr>
                                <tr>
                                    <td className="d-table-cell" style={{width: 'auto'}}></td>
                                    <td className="d-table-cell" style={{width: '20%'}}>Labor (OT)</td>
                                    <td className="d-table-cell" style={{width: '15%'}}>{Number(services.OvertimeHours).toFixed(2)}</td>
                                    <td className="d-table-cell" style={{width: '15%'}}>{props.formatter.format(Number(services.OvertimeRate))}</td>
                                    <td className="d-table-cell text-end" style={{width: 'auto'}}>{props.formatter.format(Number(services.OvertimeHours) * Number(services.OvertimeRate))}</td>
                                </tr>
                            </>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default React.memo(ServiceCalls);