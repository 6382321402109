import {useUnlockAccount} from "../../hooks/useUnlockAccount";
import {UnlockAccountProps} from "../../interfaces/viewAccountsInterfaces";
import {useFetchEmployeeAccounts} from "./useFetchEmployeeAccounts";

export function useSubmitUnlockEmployeeAccount(props: UnlockAccountProps, windowWidth: number) {
    const submitUnlockAccount = useUnlockAccount(props, windowWidth);
    const fetchAccounts = useFetchEmployeeAccounts(windowWidth);

    return async () => {
        await submitUnlockAccount.handleUnlockAccount();
        await fetchAccounts.fetchAccounts();
    };
}