import {Navigate, Route, Routes} from "react-router-dom";
import AlternativeLayout from "./common/layout/AlternativeLayout";
import MainLayout from "./common/layout/MainLayout";
import HomePage from "./homepage/Homepage";
import React, {lazy, Suspense} from "react";
import Loading from "./common/Loading";

const LazyReceipt = lazy(() => import('./receipt/Receipt'));
const LazyTermsAndConditions = lazy(() => import('./policies/TermsAndConditions'));
const LazyPaymentHistory = lazy(() => import('./paymentHistory/PaymentHistory'));
const LazyInvoiceHistory = lazy(() => import('./invoiceHistory/InvoiceHistory'));
const LazyPaymentPage = lazy(() => import('./payment/PaymentSummarySidebar'));
const LazyUpdatePassword = lazy(() => import('./oneTimePasswordUpdate/UpdatePassword'));
const LazyContextProvider = lazy(() => import('./globalContext/Context'));
const LazyWallet = lazy(() => import('./wallet/Wallet'));

function CustomerRoutes() {

    if(localStorage.getItem('temporary') === 'true') {
        return (
            <Routes>
                <Route path="/update-password" element={<AlternativeLayout component={<LazyUpdatePassword />}/>}/>
                <Route path="*" element={<Navigate to="/update-password" />} />
            </Routes>
        );
    }

    return (
        <LazyContextProvider>
            <Routes>
                <Route path="/terms-and-conditions" element={<AlternativeLayout component={<LazyTermsAndConditions />} />} />
                <Route path="/" element={<AlternativeLayout component={<HomePage />} />} />
                <Route path="/receipt" element={<AlternativeLayout component={<LazyReceipt />} />} />
                <Route path="/pay-my-bill" element={<AlternativeLayout component={<Suspense fallback={<Loading/>}><LazyPaymentPage/></Suspense>} />} />
                <Route path="/payment-history" element={<AlternativeLayout component={<LazyPaymentHistory />} />} />
                <Route path="/invoice-history" element={<AlternativeLayout component={<LazyInvoiceHistory />} />} />
                <Route path="/wallet" element={<AlternativeLayout component={<LazyWallet />} />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </LazyContextProvider>
    );
}

export default React.memo(CustomerRoutes);