import '../../resources/MainLayout.css'
import React from "react"
import PanelTemplate from "../common/ModuleComponent"
import Collapsible from "../common/InnerModuleComponent"
import TotalBox from "../common/TotalPanel"
import {
    MeterReading,
    MeterSummary,
    MeterConciseSummary
} from "./interfaces/contractInterfaces"
import { GlobalContext } from '../../globalContext/Context'
import {currencyFormatterUSD, numberFormatterWithCommas} from "../../common/miscFunctions";

function PrintContract() {
    const stateTaxAuthorities = new Set<number>([7, 8, 9, 10, 11, 12, 13, 75]);
    const invoices = GlobalContext().contractInvoices;
    const groupMeterReadings = GlobalContext().meterReadings;
    const contracts = GlobalContext().contracts;
    const taxes = GlobalContext().contractTaxes;
    const locations = GlobalContext().locations;

    const invoiceGrandTotal = GlobalContext().contractCharge;
    const totalStateTax = taxes.reduce((sum, taxes) => (stateTaxAuthorities.has(Number(taxes.TaxAuthorityID))) ? sum + Number(taxes.Amount) : sum, 0);
    const totalCountyTax = taxes.reduce((sum, taxes) => (!stateTaxAuthorities.has(Number(taxes.TaxAuthorityID))) ? sum + Number(taxes.Amount) : sum, 0);
    const totalTax = totalStateTax + totalCountyTax

    const freightTotal = invoices.reduce((sum, invoice) => sum + Number(invoice.FreightCharges), 0);
    const discountTotal = invoices.reduce((sum, invoice) => sum + Number(invoice.Discount), 0);
    const subtotal = invoiceGrandTotal - freightTotal + discountTotal - totalTax;

    const filteredMeterReadingsEquipment = () => {
        let groupedMeters:Map<string, MeterReading[]> = new Map();
        groupMeterReadings.forEach((meterReading) => {
            let readings = groupedMeters.get(meterReading.EquipmentNumber) || [];
            readings?.push(meterReading);
            groupedMeters.set(meterReading.EquipmentNumber, readings);
        });
        return groupedMeters;
    }

    const filteredEquipmentPerLocation = () => {
        let equipmentNumbersPerLocation:Map<number, Set<string>> = new Map();
        groupMeterReadings.forEach((meterReading) => {
            let reading = equipmentNumbersPerLocation.get(meterReading.CustomerID) || new Set();
            reading.add(meterReading.EquipmentNumber);
            equipmentNumbersPerLocation.set(meterReading.CustomerID, reading);
        });
        return equipmentNumbersPerLocation;
    }

    return (
        <React.Fragment>

            <div className="container-fluid m-0 p-0">
                <PanelTemplate
                    color="#E32412"
                    title="Print - Contract"
                    total={Number(invoiceGrandTotal).toFixed(2)}
                >

                    {/* Contracts */}
                    {contracts.map((contract) => {

                        // Filtering
                        const filteredMeterReadings: MeterReading[] = groupMeterReadings.filter((readings) => Number(readings.ContractID) === Number(contract.ContractID));
                        const filteredInvoices = invoices.filter((invoice) => Number(invoice.ContractID) == Number(contract.ContractID));
                        const baseCharge = filteredInvoices.reduce((sum, invoice) => sum + Number(invoice.BaseCharge), 0);

                        // Totals
                        const contractSubTotal = filteredInvoices.reduce((sum, invoices) => sum + Number(invoices.TotalInvoiceCharge) - Number(invoices.TotalTax) - Number(freightTotal) + Number(discountTotal), 0);
                        const generateKey = (summary: MeterSummary): string => {
                            return `${summary.InvoiceID}-${summary.TypeOfMeter}-${Number(summary.CoveredCopies)}-${Number(summary.TotalGroupCopies)}-${Number(summary.EffectiveRate)}`;
                        };

                        let filteredMeterSummaries: Map<string, MeterSummary> = new Map();

                        filteredMeterReadings.map((reading) => {

                            const key = generateKey(reading);

                            if(!filteredMeterSummaries.has(key)) {
                                filteredMeterSummaries.set(key, reading);
                            }

                        });

                        const uniqueMeterSummaries:Set<MeterSummary> = new Set(filteredMeterSummaries.values());

                        let conciseMeterSummaries = new Map<string, MeterConciseSummary>;

                        uniqueMeterSummaries.forEach(summary => {
                            const key = `${summary.TypeOfMeter}-${Number(summary.EffectiveRate)}`;

                            if(!conciseMeterSummaries.has(key)) {
                                let newSummary:MeterConciseSummary = {TypeOfMeter: summary.TypeOfMeter, EffectiveRate: summary.EffectiveRate, CoveredCopies: summary.CoveredCopies, TotalCopies: summary.TotalGroupCopies}

                                conciseMeterSummaries.set(`${summary.TypeOfMeter}-${Number(summary.EffectiveRate)}`,
                                    newSummary)
                            } else {
                                let existingSummary:MeterConciseSummary = conciseMeterSummaries.get(key) as MeterConciseSummary
                                let newSummary:MeterConciseSummary = {TypeOfMeter: existingSummary.TypeOfMeter, EffectiveRate: existingSummary.EffectiveRate, CoveredCopies: Number(existingSummary.CoveredCopies) + Number(summary.CoveredCopies), TotalCopies: Number(existingSummary.TotalCopies) + Number(summary.TotalGroupCopies)}
                                conciseMeterSummaries.set(`${summary.TypeOfMeter}-${Number(summary.EffectiveRate)}`, newSummary as MeterConciseSummary)
                            }
                        });

                        return (
                            <> {contractSubTotal > 0 ? (
                                <Collapsible
                                    title={`Contract No. ${contract.ContractNumber}`} total={contractSubTotal.toString()}
                                >
                                    <div style={{ background: 'white' }}>
                                        {conciseMeterSummaries.size > 0 ? (
                                            <>
                                                <br/>
                                                <p className="caption-top fw-bold text-dark mb-0"
                                                   style={{marginLeft: '8px'}}>Printing - Usage</p>
                                                <div className="w-100" style={{overflowX: 'auto'}}>
                                                    <table className="table table-borderless table-responsive">
                                                        {/* Table headings */}
                                                        <thead>
                                                        <tr className='table-headerfont'>
                                                            <th scope="col" style={{width: '60%', fontSize: 14}}>Type
                                                            </th>
                                                            <th scope="col"
                                                                style={{width: '15%', fontSize: 14}}>Covered
                                                            </th>
                                                            <th scope="col" style={{width: '15%', fontSize: 14}}
                                                                className="text-end text-md-start">Used
                                                            </th>
                                                            <th scope="col" className="hideCol text-end"
                                                                style={{width: '15%', fontSize: 14}}>Remaining
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        {/* Table body */}
                                                        <tbody>
                                                        {Array.from(conciseMeterSummaries.values()).map((summary) => {
                                                            return (
                                                                <tr>
                                                                    <td>{summary.TypeOfMeter}</td>
                                                                    <td>{numberFormatterWithCommas.format(Number(summary.CoveredCopies))}</td>
                                                                    <td className="text-end text-md-start">{numberFormatterWithCommas.format(Number(summary.TotalCopies))}</td>
                                                                    <td className="hideCol text-end">{numberFormatterWithCommas.format(Number(summary.CoveredCopies) - Number(summary.TotalCopies))}</td>
                                                                </tr>)
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        ) : (<></>)}

                                        {/* Charges */}
                                        <p className="caption-top fw-bold text-dark mb-0"
                                           style={conciseMeterSummaries.size > 0 ? {marginLeft: '8px'} : {marginLeft: '8px', paddingTop: '1rem'}}>Printing - Charges</p>
                                        <div className="w-100" style={{overflowX: 'auto'}}>
                                            <table className="table table-borderless table-responsive">
                                                {/* Overage charge headings */}
                                                <thead>
                                                <tr className='table-headerfont'>
                                                    <th style={{width: '60%', fontSize: 14}}>Type</th>
                                                    <th style={{width: '15%', fontSize: 14}}>Rate</th>
                                                    <th style={{width: '15%', fontSize: 14}}>Billable</th>
                                                    <th style={{width: '15%', fontSize: 14}}
                                                        className="text-end">Charge
                                                    </th>
                                                </tr>
                                                </thead>
                                                {/* Overage charge body */}
                                                <tbody>
                                                <tr>
                                                    <td>Base Charge</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="text-end">{currencyFormatterUSD.format(baseCharge)}</td>
                                                </tr>
                                                {Array.from(conciseMeterSummaries.values()).map((summary) => {
                                                    const billable = Number(summary.CoveredCopies) < Number(summary.TotalCopies)  ? Math.abs(Number(summary.CoveredCopies) - Number(summary.TotalCopies)) : 0;

                                                    return billable > 0 ? (
                                                        <tr>
                                                            <td>{summary.TypeOfMeter} Overage Charge</td>
                                                            <td>${Number(summary.EffectiveRate)}</td>
                                                            <td>{numberFormatterWithCommas.format(billable)}</td>
                                                            <td className="text-end">{currencyFormatterUSD.format(billable * Number(summary.EffectiveRate))}</td>
                                                        </tr>
                                                    ) : (
                                                        <></>
                                                    );
                                                })}
                                                </tbody>
                                            </table>
                                        </div>

                                        {filteredMeterReadings.length > 0 ? (
                                            <>
                                                <p className="caption-top fw-bold text-dark mb-0 pb-2"
                                                   style={{ fontSize: '1.15rem', marginLeft: '8px' }}>Printing - Activity</p>

                                                {locations.map((location, index) => {
                                                    return filteredMeterReadings.filter(summary => Number(summary.CustomerID) === Number(location.CustomerID)).length === 0 ? (<></>) :
                                                        (
                                                            <div key={index} style={{ marginLeft: '8px', marginRight: '12px' }}>
                                                                <p className="caption-top fw-bold text-dark mb-0">Equipment Details</p>
                                                                <div className="w-100" style={{ overflowX: 'auto', marginBottom: '8px' }}>
                                                                    <table>
                                                                        <thead>
                                                                        <tr >
                                                                            <th>{location.CustomerName}</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr>
                                                                            <td>{location.Address}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{location.City + ", " + location.State + " " + location.Zip}</td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                {(filteredMeterReadings.length !== 0) ? (
                                                                    <p className="caption-top fw-bold text-dark mb-0">Meter Summaries</p>
                                                                ) : <></>
                                                                }
                                                                <>
                                                                    {Array.from(filteredEquipmentPerLocation().get(location.CustomerID) || []).map((equipment, equipmentIndex) => {
                                                                        return(
                                                                            <Collapsible key={equipmentIndex} open title={equipment.includes("ADS") ? equipment : 'ADS-' + equipment}>
                                                                                <div >
                                                                                    <div className="p-2"
                                                                                         style={{background: "white", overflowX: 'auto'}} >
                                                                                        <p className="caption-top fw-bold text-dark mb-0">Meter
                                                                                            Summary</p>
                                                                                        <table
                                                                                            className="table table-borderless table-responsive">
                                                                                            <thead>
                                                                                            <tr className='table-headerfont'>
                                                                                                <th className="d-none d-md-table-cell" style={{
                                                                                                    width: '25%',
                                                                                                    fontSize: 14
                                                                                                }}>Serial
                                                                                                    Number
                                                                                                </th>
                                                                                                <th style={{
                                                                                                    width: '15%',
                                                                                                    fontSize: 14
                                                                                                }}>Meter
                                                                                                    Type
                                                                                                </th>
                                                                                                <th style={{
                                                                                                    width: '15%',
                                                                                                    fontSize: 14
                                                                                                }}>Start
                                                                                                </th>
                                                                                                <th style={{
                                                                                                    width: '15%',
                                                                                                    fontSize: 14
                                                                                                }}>End
                                                                                                </th>
                                                                                                <th style={{
                                                                                                    width: '15%',
                                                                                                    fontSize: 14
                                                                                                }}>Used
                                                                                                </th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                            {filteredMeterReadingsEquipment().get(equipment)?.map((meterReading, meterReadingIndex) => {
                                                                                                return (
                                                                                                    <tr key={meterReadingIndex}>
                                                                                                        <td className="d-none d-md-table-cell">{meterReadingIndex === 0 && meterReading.SerialNumber}</td>
                                                                                                        <td>{meterReading.TypeOfMeter}</td>
                                                                                                        <td>{numberFormatterWithCommas.format(Number(meterReading.BeginMeterDisplay))}</td>
                                                                                                        <td>{numberFormatterWithCommas.format(Number(meterReading.EndMeterDisplay))}</td>
                                                                                                        <td>{numberFormatterWithCommas.format(Number(meterReading.CountedCopies))}</td>
                                                                                                    </tr>
                                                                                                );
                                                                                            }) || (<></>)}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </Collapsible>
                                                                        );
                                                                    })}
                                                                </>
                                                            </div>
                                                        );
                                                })}
                                            </>
                                        ) : (<></>)}
                                    </div>
                                </Collapsible>
                            ) : (<></>)}
                            </>
                        );

                    })}
                    <div className="w-100" style={{overflowX: 'auto'}}>
                        <TotalBox
                            sub_total={Number(subtotal).toFixed(2)}
                            total_title="Contract Total"
                            total_tax={Number(totalTax).toFixed(2)}
                            total_total={Number(invoiceGrandTotal).toFixed(2)}
                            color="#E32412"
                        >
                            {/* Freight Charge Totals*/}
                            <div className="d-flex w-100">
                                <div className="col-4" style={{textAlign: 'start'}}>Freight</div>
                                <div className="col-8 text-end">{currencyFormatterUSD.format(freightTotal)}</div>
                            </div>
                            {/* Discount Totals*/}
                            <div className="d-flex w-100">
                                <div className="col-4" style={{textAlign: 'start'}}>Discount</div>
                                <div className="col-8 text-end">{currencyFormatterUSD.format(discountTotal)}</div>
                            </div>
                            {/* County tax */}
                            <div className="d-flex w-100">
                                <div className="col-4" style={{textAlign: 'start'}}>County</div>
                                <div className="col-8 text-end">{currencyFormatterUSD.format(totalCountyTax)}</div>
                            </div>
                            {/* State tax */}
                            <div className="d-flex w-100">
                                <div className="col-4" style={{textAlign: 'start'}}>State</div>
                                <div className="col-8 text-end">{currencyFormatterUSD.format(totalStateTax)}</div>
                            </div>
                        </TotalBox>
                    </div>
                </PanelTemplate>
            </div>
        </React.Fragment>

    )
}

export default React.memo(PrintContract);