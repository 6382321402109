import {
    Invoice,
    Location,
    Taxes,
    Service,
    Supplies,
    Equipment,
    MiscCharges,
    SalesOrder
} from "../interfaces/sseInterfaces";

export async function GetInvoices():Promise<Invoice[]> {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/invoices/list/serviceCalls`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                }
            }
        );
        if (!response.ok) {
            throw new Error('Failed to fetch invoices')
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function GetLocation(): Promise<Location[]> {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/locations/list', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                }
            }
        );
        if (!response.ok) {
            throw new Error('Failed to fetch location')
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function GetEquipment():Promise<Equipment[]> {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/equipment/non-contract/list', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                }
            }
        );
        if (!response.ok) {
            throw new Error('Failed to fetch invoices')
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function GetTaxes():Promise<Taxes[]> {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/taxes/list/serviceCalls`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                }
            }
        );
        if (!response.ok) {
            throw new Error('Failed to fetch taxes')
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function GetSupplies():Promise<Supplies[]> {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/supplies/list/supplies`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                }
            }
        );
        if (!response.ok) {
            throw new Error('Failed to fetch supplies')
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function GetMaterials():Promise<Supplies[]> {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/supplies/list/materials`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                }
            }
        );
        if (!response.ok) {
            throw new Error('Failed to fetch supplies')
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function GetService():Promise<Service[]> {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/service/list`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                }
            }
        );
        if (!response.ok) {
            throw new Error('Failed to fetch services')
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function GetMiscCharges():Promise<MiscCharges[]> {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/misc-charges/list`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                }
            }
        );
        if (!response.ok) {
            throw new Error('Failed to fetch misc charges')
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function GetSalesOrders():Promise<SalesOrder[]> {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/sales-orders/list`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                }
            }
        );
        if (!response.ok) {
            throw new Error('Failed to fetch sales orders!')
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}