import {
    EquipmentProps,
} from "./interfaces/sseInterfaces";

import React from "react";
import PanelTemplate from "../common/ModuleComponent";
import Collapsible from "../common/InnerModuleComponent";
import { GlobalContext } from "../../globalContext/Context";
import {currencyFormatterUSD, titleCase} from "../../common/miscFunctions";
import MiscCharges from "./MiscCharges";
import SSETotalsPanel from "./SSETotalsPanel";
import Supplies from "./Supplies";
import ServiceCalls from "./ServiceCalls";
import Materials from "./Materials";
import SalesOrders from "./SalesOrders";

function PrintSSEComponent() {

    // Tax Authorities
    const stateTaxAuthorities = new Set<number>([7, 8, 9, 10, 11, 12, 13, 75])

    // Data & Setters
    const invoices = GlobalContext().sseInvoices;
    const locations = GlobalContext().locations
    const equipments = GlobalContext().sseEquipment;
    const supplies = GlobalContext().supplies;
    const materials = GlobalContext().materials;
    const services = GlobalContext().serviceCalls;
    const taxes = GlobalContext().sseTaxes;
    const miscCharges = GlobalContext().miscCharges;
    const salesOrders = GlobalContext().salesOrders;

    // Collect sums
    const invoiceSSEGrandTotal = GlobalContext().servicesCharge;
    const invoiceTaxSum = taxes.reduce((sum, invoice) => sum + Number(invoice.Amount), 0);
    const invoiceStateTaxSum = taxes.reduce((sum, taxes) => stateTaxAuthorities.has(Number(taxes.TaxAuthorityID)) ? sum + Number(taxes.Amount) : sum, 0);
    const invoiceCountyTaxSum = taxes.reduce((sum, taxes) => !stateTaxAuthorities.has(Number(taxes.TaxAuthorityID)) ? sum + Number(taxes.Amount) : sum, 0);
    const invoiceFreightSum = invoices.reduce((sum, invoice) => sum + Number(invoice.FreightCharges), 0);
    const invoiceDiscountSum = invoices.reduce((sum, invoice) => sum + Number(invoice.Discount), 0);

    function InnerComponent(props: EquipmentProps) {
        const {Equipment} = props;

        const equipmentElements =
            Equipment.map(filteredEquip => {
                const filteredSupplies = supplies.filter((supp) => Number(supp.EquipmentID) === Number(filteredEquip.EquipmentID) && Number(supp.CustomerID) === Number(filteredEquip.CustomerID));
                const filteredMaterials = materials.filter((material) => Number(material.EquipmentID) === Number(filteredEquip.EquipmentID) && Number(material.CustomerID) === Number(filteredEquip.CustomerID));
                const filteredServices = services.filter((service) => Number(service.EquipmentID) === Number(filteredEquip.EquipmentID) && Number(service.CustomerID) === Number(filteredEquip.CustomerID));
                const filteredMiscCharges = miscCharges.filter((misc) => Number(misc.EquipmentID) === Number(filteredEquip.EquipmentID) && Number(misc.CustomerID) === Number(filteredEquip.CustomerID));
                const filteredSuppliesTotal = filteredSupplies.reduce((sum, sup) => (sum + Number(sup.ItemsPrice)), 0);
                const filteredMaterialsTotal = filteredMaterials.reduce((sum, mat) => (sum + Number(mat.ItemsPrice)),0);
                const filteredServicesTotal = filteredServices.reduce((sum, serv) => (sum + Number(serv.LaborTotal)), 0);
                const filteredMiscChargesTotal = filteredMiscCharges.reduce((sum, miscCharges) => (sum + Number(miscCharges.MiscCharges)),0);

                const totalCost = Number(filteredSuppliesTotal) + Number(filteredMaterialsTotal) + Number(filteredServicesTotal) + Number(filteredMiscChargesTotal);

                return (
                    totalCost === 0 ? <></> : (<Collapsible
                        title={(filteredEquip.EquipmentID ? (filteredEquip.EquipmentNumber.includes("ADS") ? filteredEquip.EquipmentNumber : 'ADS-'+ filteredEquip.EquipmentNumber) : 'ADS - Non Equipment')}
                        total={totalCost.toFixed(2)}
                        key={filteredEquip.EquipmentID}
                    >
                        <div className="p-2" style={{background: "white"}}>

                            {/* Supplies */}
                            {filteredSupplies.length > 0 ? (
                                <Supplies supplies={filteredSupplies} formatter={currencyFormatterUSD}/>) : (<></>)}

                            {filteredMaterials.length > 0 || filteredServices.length > 0 ? (
                                <p className="caption-top fw-bold text-dark mb-1">Service Calls</p>
                                ) : (<></>)}

                            {/* Service Calls */}
                            {filteredServices.length > 0 ? (
                                <ServiceCalls serviceCalls={filteredServices} formatter={currencyFormatterUSD}/>) : (<></>)}

                            {/* Materials */}
                            {filteredMaterials.length > 0 ? (
                                <Materials materialChargesList={filteredMaterials} formatter={currencyFormatterUSD}/>) : (<></>)}

                            {/* Misc Charges */}
                            {filteredMiscCharges.length > 0 ? (
                                <MiscCharges miscChargeList={filteredMiscCharges} formatter={currencyFormatterUSD} />) : (<></>)}
                        </div>
                    </Collapsible>)

                );
            })
        return <>{equipmentElements}</>;
    }

    return (
        <>
            <PanelTemplate
                color="#FF8F1C"
                title="Print - Supplies, Service & Equipment"
                total={Number(invoiceSSEGrandTotal).toFixed(2)}
            >
                {locations.map((location) => {
                    const numberOfLocations = locations.length;
                    const filteredEquipment = equipments.filter((equip) => Number(equip.CustomerID) === Number(location.CustomerID));

                    const filteredInvoices = invoices.filter(invoice => Number(invoice.CustomerID) === Number(location.CustomerID));
                    const locationTotal = filteredInvoices.reduce((sum, invoice) => sum + Number(invoice.LeftToPay), 0);
                    const locationTaxTotal = filteredInvoices.reduce((sum, invoice) => sum + Number(invoice.TotalTax), 0);
                    const locationDiscount = filteredInvoices.reduce((sum, invoice) => sum + Number(invoice.Discount), 0);
                    const locationFreight = filteredInvoices.reduce((sum, invoice) => sum + Number(invoice.FreightCharges), 0);
                    const locationSubtotal = locationTotal - locationTaxTotal + locationDiscount - locationFreight;
                    const filteredSalesOrders = salesOrders.filter((salesOrder) => Number(salesOrder.customerID) === Number(location.CustomerID));

                    if(filteredEquipment[0]) { // creates an empty equipment field to store supplies, services, misc charges, or material charges with no equipment id specified.
                    filteredEquipment.push(
                        {
                            CustomerID: filteredEquipment[0].CustomerID,
                            LocationID: filteredEquipment[0].LocationID,
                            EquipmentID: 0,
                            EquipmentNumber: "",
                            Address: filteredEquipment[0].Address,
                            City: filteredEquipment[0].City,
                            Zip: filteredEquipment[0].Zip,
                            State: filteredEquipment[0].State
                        });
                    }

                    return (
                        <>
                            {numberOfLocations > 1 ? (
                                // Number of locations > 1
                                <>
                                    {locationTotal > 0 ? ( // Amount Due at location > 0
                                        <Collapsible title={`${titleCase(location.Address)}, ${location.City} - ${location.CustomerName}`}
                                                     key={location.CustomerID}
                                                     total={Number(locationSubtotal).toFixed(2)}>
                                            <div className="p-2" style={{background: "white"}}>
                                                <div className="ml-0 mb-3 mt-3">
                                                    <p className="caption-top fw-bold text-dark mb-2">Equipment Details</p>
                                                    <h4 className="mb-2">{location.CustomerName}</h4>
                                                    <h4 className="mb-2">{(location?.Address || filteredEquipment[0]?.Address)} </h4>
                                                    <h4 className="mb-2">{titleCase(location?.City || filteredEquipment[0]?.City)}, {(location?.State || filteredEquipment[0]?.State).toUpperCase()} {location?.Zip || filteredEquipment[0]?.Zip}</h4>
                                                </div>
                                                <SalesOrders salesOrders={filteredSalesOrders} formatter={currencyFormatterUSD}/>
                                                <InnerComponent Equipment={filteredEquipment}/>
                                            </div>
                                        </Collapsible>
                                    ): (<></>)}
                                </>
                                ) : (
                                // Number of locations = 1
                                <>
                                    <div className="container-fluid p-0 mb-4 mt-1">
                                        <p className="caption-top fw-bold text-dark mb-2">Equipment Details</p>
                                        <h4 className="mb-2">{location.CustomerName}</h4>
                                        <h4 className="mb-2">{titleCase(location?.Address)} </h4>
                                        <h4 className="mb-2">{titleCase(location?.City)}, {(location?.State)?.toUpperCase()} {location?.Zip}</h4>
                                    </div>
                                    <SalesOrders salesOrders={filteredSalesOrders} formatter={currencyFormatterUSD}/>
                                    <InnerComponent Equipment={filteredEquipment}/>
                                </>)}
                        </>
                    );
                })}

                <SSETotalsPanel invoiceGrandTotal={invoiceSSEGrandTotal}
                               invoiceDiscountSum={invoiceDiscountSum}
                               invoiceFreightSum={invoiceFreightSum}
                               invoiceTaxSum={invoiceTaxSum}
                               invoiceCountyTaxSum={invoiceCountyTaxSum}
                               invoiceStateTaxSum={invoiceStateTaxSum}
                               formatter={currencyFormatterUSD}/>
            </PanelTemplate>
        </>
    );
}

export default  React.memo(PrintSSEComponent);