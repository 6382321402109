import {errorToast} from "../../common/toasts/toastMessages";

export default function useGetLatestSummaryInvoicePDF(windowWidth: number) {

    const fetchPDF = async() => {
        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/pdf/get-latest-summary-pdf', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
                },
            });

            if (response.ok) {
                const blob = await response.blob();
                if(blob.size === 0) {
                    errorToast("No invoice is available at this time. Please check again later.", windowWidth);
                    return null;
                }
                return URL.createObjectURL(blob);
            } else {
                const data = await response.json();
                errorToast(data.message, window.innerWidth);
                return null;
            }
        } catch (error) {
            errorToast('An error occurred. Please try again later.', windowWidth);
            return null;
        }
    }

    return {fetchPDF};
}