import {Navigate, Route, Routes} from "react-router-dom";
import React, {lazy} from "react";
import CreateAccount from "./internal/createAccount/CreateCustomerAccount";
import AlternativeLayout from "./common/layout/AlternativeLayout";
import {useAuthStore} from "./auth/store/useAuthStore";
import CreateInternalAccount from "./internal/createAccount/CreateInternalAccount";
import ViewEmployeeAccounts from "./internal/viewAccounts/viewEmployeeAccounts/ViewEmployeeAccounts";

const LazyInternalHome = lazy(() => import('./internal/homepage/InternalHome'));
const LazyUpdatePassword = lazy(() => import('./oneTimePasswordUpdate/UpdatePassword'));

function InternalRoutes() {

    const {permissions} = useAuthStore((state) => ({
        permissions: state.permissions
    }));

    if(localStorage.getItem('temporary') === 'true') {
        return (
            <Routes>
                <Route path="/update-password" element={<AlternativeLayout component={<LazyUpdatePassword />}/>}/>
                <Route path="*" element={<Navigate to="/update-password" />} />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<AlternativeLayout component={<LazyInternalHome />} />} />
            {permissions.has('CREATE_CUSTOMER_ACCOUNT') && <Route path="/create-customer-account" element={<AlternativeLayout component={<CreateAccount />} />} />}
            {permissions.has('CREATE_EMPLOYEE_ACCOUNT') && <Route path="/create-employee-account" element={<AlternativeLayout component={<CreateInternalAccount />} />} />}
            {permissions.has('VIEW_ALL_EMPLOYEE_ACCOUNTS') && <Route path="/view-employee-accounts" element={<AlternativeLayout component={<ViewEmployeeAccounts />} />} />}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}

export default React.memo(InternalRoutes);