import {useToastAutoDismiss} from "../../common/hooks/useToastAutoDismiss";
import useWindowWidth, {LG_SCREEN_WIDTH_PX} from "../../common/hooks/useWindowWidth";
import React, {useState} from "react";
import InternalSidePanel from "../homepage/InternalSidePanel";
import {useFetchRoles} from "./hooks/useFetchRoles";
import {useAuthStore} from "../../auth/store/useAuthStore";
import {Role} from "./interfaces/createInternalAccountInterfaces";
import * as yup from 'yup';
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useCreateAccount} from "./hooks/useCreateAccount";


function CreateInternalAccount() {
    document.title = 'Create Account | AD Solutions'
    useToastAutoDismiss();
    const windowWidth = useWindowWidth();
    useFetchRoles(windowWidth);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const {roles, permissions} = useAuthStore((state) => ({
        roles: state.roles,
        permissions: state.permissions
    }));

    const createAccount = useCreateAccount();

    const onSubmit = async(data: any) => {
        setIsSubmitting(true);
        const response = await createAccount(data, windowWidth, reset, '/api/account/create-internal');
        setIsSubmitting(false);
    }

    const assembleCreatableInternalRoles = (roles: Role[]) => {
        return roles.filter((role) => {
            if(!permissions.has('CREATE_ADMIN_ACCOUNT')) {
                return !role.roleName.includes('ADMIN');
            }
            return true
        }).filter((role) => role.roleName !== 'CUSTOMER');
    }

    const schema = yup.object().shape({
        emailAddress: yup.string().email('Invalid email address').required('An email is required'),
        firstName: yup.string().trim().min(2,"First name must be at least two characters.").required('A first name is required'),
        lastName: yup.string().trim().min(2,"Last name must be at least two characters.").required('A last name is required'),
        pipedriveID: yup.string().required('A pipedrive user id is required.'),
        companyPositionTitle: yup.string().min(3,"Job title must be at least three characters.").required('A job title is required.'),
        role: yup.string().min(4,"Role name must be at least four characters.").required('A role is required.'),
        phoneNumber: yup.string().nullable()
    });

    const { control, handleSubmit, formState: { errors }, reset , register} = useForm({
        resolver: yupResolver(schema)
    });

    return (
        <div className="container-xl" style={windowWidth > LG_SCREEN_WIDTH_PX ? {paddingTop: '9rem', paddingBottom: '1.6rem'} : {paddingTop: '0'}}>
            <div className="d-flex justify-content-center p-0 px-3 pb-3 row">
                <InternalSidePanel/>
                <div className="col-12 col-xl-9" style={{backgroundColor: 'white', borderRadius: '12px', border: '2px solid #E6E7E8'}}>
                    <div className="p-4">
                        <h3 style={{fontSize: '2.5rem'}}>Create Internal Account</h3>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <h3 className="pt-3 mb-0" style={{fontSize: '1.4rem'}}>Employee Identification</h3>
                                <div className="col-12" title="An email address is required.">
                                    <label htmlFor="emailAddress" className="form-label labels">Email Address <span
                                        className="text-danger"
                                    >*</span></label>
                                    <Controller control={control} defaultValue="" render={({field}) => (
                                        <input type="email" className="form-control" id="emailAddress"
                                               {...field}
                                               placeholder="user@myadsusa.com" required/>
                                    )} name="emailAddress"></Controller>
                                    {errors.emailAddress && <p className="text-danger mb-0 p-0" style={{fontWeight:'lighter'}}>{errors.emailAddress.message}</p>}
                                </div>
                            </div>

                            <hr style={{marginTop: '2.6rem', marginBottom: '2.4rem'}}/>

                            <div className="row">
                                <h3 className="mb-0" style={{fontSize: '1.4rem'}}>Personal Information</h3>
                                <div className="col-12 col-lg-6" title="A first name is required.">
                                    <label htmlFor="firstName" className="form-label labels">First Name <span
                                        className="text-danger"
                                    >*</span></label>
                                    <Controller control={control} defaultValue="" render={({field}) => (
                                    <input type="text" className="form-control" id="firstName"
                                           {...field}
                                           placeholder="John" required/>
                                    )} name="firstName"></Controller>
                                    {errors.firstName && <p className="text-danger mb-0 p-0" style={{fontWeight:'lighter'}}>{errors.firstName.message}</p>}
                                </div>

                                <div className="col-12 col-lg-6" title="A last name is required.">
                                    <label htmlFor="lastName" className="form-label labels">Last Name <span
                                        className="text-danger"
                                    >*</span></label>
                                    <Controller control={control} defaultValue="" render={({field}) => (
                                    <input type="text" className="form-control" id="lastName" {...field}
                                           placeholder="Doe" required/>
                                    )} name="lastName"></Controller>
                                    {errors.lastName && <p className="text-danger mb-0 p-0" style={{fontWeight:'lighter'}}>{errors.lastName.message}</p>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label htmlFor="phoneNumber" className="form-label labels">Phone Number</label>
                                    <input type="text" className="form-control" id="phoneNumber" defaultValue=""

                                           placeholder="(123) 456-7890" {...register('phoneNumber')}/>
                                </div>

                                <div className="col-12 col-lg-6" title="A job title is required.">
                                    <label htmlFor="companyPositionTitle" className="form-label labels">Job
                                        Title <span
                                            className="text-danger"
                                        >*</span></label>
                                    <Controller control={control} defaultValue="" render={({field}) => (
                                    <input type="text" className="form-control" id="companyPositionTitle"
                                           {...field}
                                           placeholder="Accountant" required/>
                                    )} name="companyPositionTitle"></Controller>
                                    {errors.companyPositionTitle && <p className="text-danger mb-0 p-0" style={{fontWeight:'lighter'}}>{errors.companyPositionTitle.message}</p>}
                                </div>
                            </div>

                            <hr style={{marginTop: '2.6rem', marginBottom: '2.4rem'}}/>

                            <div className="row">
                                <h3 className="mb-0" style={{fontSize: '1.4rem'}}>Account Information</h3>
                                <div className="col-12 col-lg-6" title="A role is required.">
                                    <label htmlFor="role" className="form-label labels">Role <span
                                        className="text-danger"
                                    >*</span></label>
                                    <Controller control={control} defaultValue="DISPATCH" render={({field}) => (
                                    <select className="form-control" {...field}>
                                        {assembleCreatableInternalRoles(Array.from(roles))
                                            .map((role, index) => (
                                                <option key={index} value={role.roleName}>{role.roleDisplayName}</option>
                                            ))}
                                    </select>
                                    )} name="role"></Controller>
                                    {errors.role && <p className="text-danger mb-0 p-0" style={{fontWeight:'lighter'}}>{errors.role.message}</p>}
                                </div>

                                <div className="col-12 col-lg-6" title="A Pipedrive user id is required to create companies and sales orders.">
                                    <label htmlFor="pipedriveID" className="form-label labels">Pipedrive User ID <span
                                        className="text-danger"
                                    >*</span></label>
                                    <Controller control={control} defaultValue="" render={({field}) => (
                                    <input type="number" className="form-control" id="pipedriveID"
                                           {...field} min={0}
                                           placeholder="123"/>
                                    )} name="pipedriveID"></Controller>
                                    {errors.pipedriveID && <p className="text-danger mb-0 p-0" style={{fontWeight:'lighter'}}>{errors.pipedriveID.message}</p>}
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="submit" className="btn create-account-button mt-4" disabled={isSubmitting}>Create Account
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(CreateInternalAccount);