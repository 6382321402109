import React from "react";
import {Link} from "react-router-dom";
import {useLogout} from "../../auth/hooks/useLogout";
import logoutIcon from "../.././common/images/logout.svg";
import useGetRoute from "../../common/hooks/useGetRoute";
import {useAuthStore} from "../../auth/store/useAuthStore";

function InternalSidePanel() {
    const location = useGetRoute();

    const {permissions} = useAuthStore((state) => ({
        permissions: state.permissions
    }));

    return (
        <div className="col-xxl-3 col-lg-3 d-flex justify-content-center justify-content-xxl-end mt-md-0">
            <div className="px-5 text-center text-xl-start mt-5 pt-5 pb-5 pb-xl-2 pt-lg-0 mt-lg-0">
                <h3 className="mb-3 ">My Tools</h3>
                <CustomerTools permissions={permissions} location={location}/>
                <InternalTools permissions={permissions} location={location}/>
                <MiscTools permissions={permissions} location={location}/>
            </div>
        </div>
    );
}


function CustomerTools(params: { permissions: Set<string>, location: string }) {
    const customerPermissions:string[] = ['CREATE_CUSTOMER_ACCOUNT', 'VIEW_ALL_CUSTOMER_ACCOUNTS',
        'LOCK_CUSTOMER_ACCOUNT', 'UNLOCK_CUSTOMER_ACCOUNT'];

    const validPermissions = customerPermissions.filter((permission) => params.permissions.has(permission));

    if(validPermissions.length === 0) {
        return (<></>);
    }

    return (
        <div className="pb-4">
            <h3 className="mb-3 " style={{fontSize: '1.3rem'}}>Customer</h3>
            {params.permissions.has('VIEW_ALL_CUSTOMER_ACCOUNTS') && (
            <Link to="/" className="text-decoration-none">
                <h4 className="mb-3" style={params.location === "/" ? {color: '#0072ce'} : {}}>View
                    Accounts</h4>
            </Link>
            )}
            {params.permissions.has('CREATE_CUSTOMER_ACCOUNT') && (
                <Link to="/create-customer-account" className="text-decoration-none">
                    <h4 className="mb-3"
                        style={params.location === "/create-customer-account" ? {color: '#0072ce'} : {}}>Create Account</h4>
                </Link>
            )}
        </div>
    );
}

function InternalTools(params: { permissions: Set<string>, location: string }) {

    const customerPermissions:string[] = ['CREATE_EMPLOYEE_ACCOUNT', 'CREATE_ADMIN_ACCOUNT', 'VIEW_ALL_EMPLOYEE_ACCOUNTS',
        'LOCK_EMPLOYEE_ACCOUNT', 'UNLOCK_EMPLOYEE_ACCOUNT', 'LOCK_ADMIN_ACCOUNT', 'UNLOCK_ADMIN_ACCOUNT'
    ];

    const validPermissions = customerPermissions.filter((permission) => params.permissions.has(permission));

    if(validPermissions.length === 0) {
        return (<></>);
    }

    return (
        <div className="pb-4">
            <h3 className="mb-3" style={{fontSize: '1.3rem'}}>Employee</h3>

            {params.permissions.has('VIEW_ALL_EMPLOYEE_ACCOUNTS') && (
                <Link to="/view-employee-accounts" className="text-decoration-none">
                    <h4 className="mb-3"
                        style={params.location === "/view-employee-accounts" ? {color: '#0072ce'} : {}}>View Accounts
                    </h4>
                </Link>
            )}

            {params.permissions.has('CREATE_EMPLOYEE_ACCOUNT') && (
                <Link to="/create-employee-account" className="text-decoration-none">
                    <h4 className="mb-3"
                        style={params.location === "/create-employee-account" ? {color: '#0072ce'} : {}}>Create
                        Account</h4>
                </Link>
            )}
        </div>
    );
}

function MiscTools(params: { permissions: Set<string>, location: string }) {

    const handleLogout = useLogout();

    return (
        <div className="pb-4">
            <h3 className="mb-3 " style={{fontSize: '1.3rem'}}>Misc</h3>
            <a href="#" onClick={handleLogout}
               className="logout-link d-flex align-items-center text-center justify-content-center justify-content-xl-start"
               style={{fontSize: '1.1rem'}}>
                <img src={logoutIcon} alt="logout"
                     style={{width: '24px', height: '24px', marginRight: '8px'}}/>Logout
            </a>
        </div>
    );
}

export default React.memo(InternalSidePanel);