import { Color } from "react-bootstrap/esm/types";


interface UProps {
    total_title: string;
    sub_total:string;
    children?: React.ReactNode
    total_tax:string;
    total_total:string;
    color: Color;
    
  }

  const TotalBox: React.FC<UProps> = ({ total_title,sub_total, total_tax,children, total_total,color }) => {
      const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
      });


return(

<div className="container-fluid mb-3 p-0 d-flex justify-content-center justify-content-sm-end justify-content-lg-end">
  <div className="contractTotalSection" style={{backgroundColor:'#F1F2F2'}}>
    <div className="d-flex w-100">
      <div className="col-4 text-start">Subtotal</div>
      <div className="col-8 text-end">{formatter.format(Number(sub_total))}</div>
    </div>
    {children}
    <div className="d-flex w-100">
      <div className="col-4 text-start">Total Tax</div>
      <div className="col-8 text-end">{formatter.format(Number(total_tax))}</div>
    </div>
    <div className="d-flex w-100" style={{ color: color, fontSize: '18px' }}>
      <div className="col-7 text-start" style={{ fontWeight: '700' }}>{total_title}</div>
      <div className="col-5 text-end" style={{ fontWeight: '700'}}>{formatter.format(Number(total_total))}</div>
    </div>
  </div>
</div>
);
  }
export default TotalBox