import {errorToast} from "../../common/toasts/toastMessages";
import {useEffect, useState} from "react";
import {useAuthStore} from "../store/useAuthStore";

export function useIsAuthenticated(windowWidth: number) {

    const [isAuth, setIsAuth] = useState<boolean | null>(null);

    const {setRole, setPermissions, setCompanyName} = useAuthStore((state) => ({
        setRole: state.setRole,
        setPermissions: state.setPermissions,
        setCompanyName: state.setCompanyName,
    }))

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/authentication/check', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                    }
                });

                if (!response.ok) {
                    localStorage.removeItem('jwtToken');
                    localStorage.removeItem('temporary');
                    setIsAuth(false);
                } else {
                    const data = await response.json();
                    setRole(data.role)
                    setPermissions(new Set(data.permissions));
                    setCompanyName(data.companyName);
                    setIsAuth(data.isAuthenticated);
                }

            } catch (error) {
                errorToast('There was an issue checking auth status. Please try again.', windowWidth);
            }
        }
        checkAuth();
    }, [localStorage.getItem('jwtToken')])

    return isAuth;
}