import {MiscChargeComponent} from "./interfaces/sseInterfaces";
import {titleCase} from "../../common/miscFunctions";
import React from "react";

function MiscCharges(props: MiscChargeComponent) {
    return (
        <>
            <p className="caption-top text-dark mb-0"><i>Misc Charges</i></p>
            <div className="w-100" style={{overflowX: 'auto'}}>
                <table className="table table-borderless table-responsive">
                    <thead className="w-100">
                    <tr className="align-content-center">
                        <th className="text-start d-table-cell" style={{width: '20%'}}>Date</th>
                        <th className="d-none d-md-table-cell" style={{width: '50%'}}>Description</th>
                        <th className="d-md-none d-table-cell" style={{width: '50%'}}>Desc</th>
                        <th className="d-table-cell justify-content-end text-end" style={{width: 'auto'}}>Charge</th>
                    </tr>
                    </thead>
                    <tbody className="w-100">
                    {props.miscChargeList.map(miscCharges => (
                        <tr>
                            <td className="d-table-cell" style={{width: '20%'}}>{new Date(miscCharges.Date).toLocaleDateString()}</td>
                            <td className="d-table-cell" style={{width: '50%'}}>{titleCase(miscCharges.MiscDescription || "Misc.")}</td>
                            <td className="d-table-cell justify-content-end text-end" style={{width: 'auto'}}>{props.formatter.format(miscCharges.MiscCharges)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default React.memo(MiscCharges);