import {TotalsComponent} from "./interfaces/sseInterfaces";
import TotalBox from "../common/TotalPanel";
import React from "react";

function SSETotalsPanel(props: TotalsComponent) {
    return (
        <TotalBox
            sub_total={Number(props.invoiceGrandTotal - props.invoiceTaxSum + props.invoiceDiscountSum - props.invoiceFreightSum).toFixed(2)}
            total_title="SS&E Total"
            total_tax={Number(props.invoiceTaxSum).toFixed(2)}
            total_total={Number(props.invoiceGrandTotal).toFixed(2)}
            color="#FF8F1C">
            <div className="d-flex w-100">
                <div className="col-4 text-start">Discount</div>
                <div className="col-8 text-end">{props.formatter.format(props.invoiceDiscountSum)}</div>
            </div>
            <div className="d-flex w-100">
                <div className="col-4">Freight</div>
                <div className="col-8 text-end">{props.formatter.format(props.invoiceFreightSum)}</div>
            </div>
            <div className="d-flex w-100">
                <div className="col-4">County</div>
                <div className="col-8 text-end">{props.formatter.format(props.invoiceCountyTaxSum)}</div>
            </div>
            <div className="d-flex w-100">
                <div className="col-4">State</div>
                <div className="col-8 text-end">{props.formatter.format(props.invoiceStateTaxSum)}</div>
            </div>
        </TotalBox>
    );
}

export default React.memo(SSETotalsPanel)