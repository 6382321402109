import '../../homepage/printContract/PrintContract';
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import '../../resources/MainLayout.css'
import '../../header/HeaderStyle.css';
import React from "react";
import {Toaster} from "react-hot-toast";

interface Components {
    component: React.JSX.Element
}

export default function MainLayout(props: Components) {

    const component = props.component;

    return (
        <>
            <Header />
            <div className="mt-lg-5 pt-lg-5 pb-3">
                <Toaster />
                <div className="mt-lg-5 align-content-center justify-content-center align-content-center w-100">
                    <div className="container-fluid mt-lg-4">
                        <div className="d-sm-block d-md-block d-lg-flex align-content-center justify-content-center">
                            {component}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}