import '../../homepage/printContract/PrintContract';
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import '../../resources/MainLayout.css'
import '../../header/HeaderStyle.css';
import React from "react";
import {Toaster} from "react-hot-toast";

interface Components {
    component: React.JSX.Element
}

export default function AlternativeLayout(props: Components) {

    const component = props.component;

    return (
        <>
            <Header />
            <Toaster />
            <>{component}</>
            <Footer />
        </>
    );
}