import {Panel, PanelHeaderTemplateOptions} from "primereact/panel";
import { Color } from "react-bootstrap/esm/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown
} from "@fortawesome/fontawesome-free-solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { motion } from "framer-motion";


interface AProps {
    title: string;
    color : Color;
    total ?: string;
    children : React.ReactNode
  }

const PanelTemplate : React.FC<AProps> = ({title, color, total, children}) =>{
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

const template = (options: PanelHeaderTemplateOptions) => {

    const toggleIcon = options.collapsed ? <FontAwesomeIcon icon={faChevronDown as IconProp} />: <FontAwesomeIcon icon={faChevronUp as IconProp} />;

    const className = `${options.className} justify-content-start`;

    return (
        <div className={className} style={!options.collapsed ? {paddingLeft:'1rem', borderRadius: '12px 12px 0 0', borderTop: '1px solid #E6E7E8', borderLeft: '1px solid #E6E7E8', borderRight: '1px solid #E6E7E8'} : {paddingLeft:'1rem', borderRadius: 12, border: '1px solid #E6E7E8'}}>
            <div className="container-fluid d-flex p-0">
                <div className="col-6 text-start">{title}</div>
                <div className="col-3 align-items-center d-flex justify-content-end" style={{color:color}}>{formatter.format(Number(total))}</div>
                <div className="col-3 text-end align-items-center d-flex justify-content-end" >

                    <button onClick={options.onTogglerClick} style={{border:'none', backgroundColor:'inherit'}}>
                        {toggleIcon}
                    </button>
                </div>
            </div>
        </div>
    );
};

return (
    <div className="m-0 p-0">

        <Panel headerTemplate={template} className="myPanel w-100" toggleable collapsed >
            <motion.div
                initial={{height: 0, opacity: 0}}
                animate={{height: 'auto', opacity: 1}}
                transition={{duration: 0.5}}
            >
                {children}
            </motion.div>
        </Panel>

</div>
)
    ;
}
export default PanelTemplate