import {AuthorizationStoreState} from "../interfaces/authorizationInterfaces";
import {create} from "zustand";
import {Role} from "../../internal/createAccount/interfaces/createInternalAccountInterfaces";

export const useAuthStore = create<AuthorizationStoreState>((set) => ({
    role: '',
    setRole: (role) => set({role}),
    permissions: new Set(),
    setPermissions: (permissions) => set({permissions}),
    roles: new Set<Role>(),
    setRoles: (roles: Set<Role>) => set({roles}),
    companyName: '',
    setCompanyName: (companyName) => set({companyName}),
}))