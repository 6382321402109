import {LockAccountProps} from "../../interfaces/viewAccountsInterfaces";
import {useLockAccount} from "../../hooks/useLockAccount";
import {useFetchEmployeeAccounts} from "./useFetchEmployeeAccounts";

export function useSubmitLockEmployeeAccount(props: LockAccountProps, windowWidth: number) {
    const submitLockAccount = useLockAccount(props, windowWidth);
    const fetchAccounts = useFetchEmployeeAccounts(windowWidth);

    return async() => {
        await submitLockAccount.handleLockAccount();
        await fetchAccounts.fetchAccounts();
    }
}