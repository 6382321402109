import {errorToast, loadingToast, successToast} from "../../../common/toasts/toastMessages";
import {UseFormReset} from "react-hook-form";

export function useCreateAccount() {
    return async (props: any, windowWidth: number, reset: UseFormReset<any>, apiPath: string) => {
        try {
            if (props.role === 'CUSTOMER' && props.eAutomateCustomerNumber === null && props.connectwiseCustomerID === null) {
                errorToast('Customers need either an eAutomate Customer number or a connectwise customer id.', windowWidth);
                return;
            }
            loadingToast('Creating account ...', windowWidth);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + apiPath, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')
                },
                body: JSON.stringify(props)
            });
            const data = await response.json()

            if (!response.ok) {
                errorToast(data.message, windowWidth);
            } else {
                successToast(data.message, windowWidth)
                reset();
            }
        } catch (error) {
            console.error(error);
            errorToast('An error occurred. Please try again.', windowWidth);
        }
    };

}