import {useAuthStore} from "../../../auth/store/useAuthStore";
import React from "react";
import useWindowWidth from "../../../common/hooks/useWindowWidth";
import {LockAndUnlockEmployeeStatusButtonProps} from "./interfaces/viewEmployeeAccountsInterface";
import {useSubmitUnlockEmployeeAccount} from "./hooks/useSubmitUnlockEmployeeAccount";
import {useSubmitLockEmployeeAccount} from "./hooks/useSubmitLockEmployeeAccount";

function UnlockAndLockEmployeeStatusUpdateButton(props: LockAndUnlockEmployeeStatusButtonProps) {
    const windowWidth = useWindowWidth();
    const submitLockAccount = useSubmitLockEmployeeAccount(props, windowWidth);
    const submitUnlockAccount = useSubmitUnlockEmployeeAccount(props, windowWidth);
    const {permissions} = useAuthStore((state) => ({
        permissions: state.permissions,
    }))

    if(!permissions.has('UNLOCK_EMPLOYEE_ACCOUNT') || !permissions.has('LOCK_EMPLOYEE_ACCOUNT')) {
        return (<></>);
    }

    return (
        <div className="d-flex align-items-center">
            {props.accountLocked ? (
                <>
                    {props.roleName.includes('ADMIN') && permissions.has('UNLOCK_ADMIN_ACCOUNT') && (
                        <button
                            className="btn btn-outline-primary d-flex justify-content-center px-3 account-update-status-button"
                            onClick={submitUnlockAccount}>Unlock
                        </button>
                    )}
                    {!props.roleName.includes('ADMIN') && permissions.has('UNLOCK_EMPLOYEE_ACCOUNT') && (
                        <button
                            className="btn btn-outline-primary d-flex justify-content-center px-3 account-update-status-button"
                            onClick={submitUnlockAccount}>Unlock
                        </button>
                    )}
                </>
            ) : (
                <>
                    {props.roleName.includes('ADMIN') && permissions.has('LOCK_ADMIN_ACCOUNT') && (
                        <button
                            className="btn btn-outline-danger d-flex justify-content-center px-3 account-update-status-button"
                            onClick={submitLockAccount}>Lock
                        </button>
                    )}

                    {!props.roleName.includes('ADMIN') && permissions.has('LOCK_EMPLOYEE_ACCOUNT') && (
                        <button
                            className="btn btn-outline-danger d-flex justify-content-center px-3 account-update-status-button"
                            onClick={submitLockAccount}>Lock
                        </button>
                    )}
                </>
            )}
        </div>
    );
}

export default React.memo(UnlockAndLockEmployeeStatusUpdateButton);