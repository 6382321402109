import React from "react";

function Footer() {
    return (
        <div className="p-0 m-0" style={{fontWeight:'400'}}>
            <footer className="footer-area">
                <section className="footer-content">
                    <div className="container">
                        <div className="footer-content-top">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="footer-content-left">
                                        <div className="footer-logo">
                                            <a href="https://myadsusa.com"><img src="https://myadsusa.com/wp-content/uploads/2022/11/ADSolutions_Logo_RGB_Inverse.svg" width="296" alt=""/></a>
                                        </div>
                                        <div className="footer-social">
                                            <ul>
                                                <li>
                                                    <a href="https://www.facebook.com/adsolutionsflorida/"
                                                       title="Facebook" target="_blank ">
                                                        <i className="fab fa-facebook-square" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://twitter.com/adsolutions_orl" title="Twitter"
                                                       target="_blank ">
                                                        <i className="fab fa-twitter" aria-hidden="true"></i> </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.youtube.com/channel/UCXyh-yRve5q3BR7ZyEVlVLA"
                                                       title="Youtube" target="_blank ">
                                                        <i className="fab fa-youtube" aria-hidden="true"></i> </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/company/advanced-document-solutions"
                                                       title="Linkedin" target="_blank ">
                                                        <i className="fab fa-linkedin-in" aria-hidden="true"></i> </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/a.d.solutions/" title="Instagram"
                                                       target="_blank ">
                                                        <i className="fab fa-instagram" aria-hidden="true"></i> </a>
                                                </li>
                                            </ul>
                                        </div>

                                        <p>AD Solutions is a woman-minority-owned business founded in 2007. We partner
                                            with world-class vendors to create best-in-class solutions.</p>
                                        <div className="phone-number-and-fax">
                                            <ul>
                                                <li><a href="tel:866-691-6347">P: 866-691-6347</a></li>
                                                <li><a href="tel:407-412-6926">F: 407-412-6926</a></li>
                                            </ul>
                                        </div>
                                        <div className="request-qoute d-lg-none d-block">
                                            <a href="/request-a-quote/">Request a Quote</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="footer-content-right">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="each-address">
                                                    <h6>Orlando HQ</h6>
                                                    <p><a href="https://g.page/adsolutions-usa?share" target="_blank">653 West Michigan Street
                                                        Orlando, FL 32805</a></p>
                                                    <a href="https://g.page/adsolutions-usa?share" target="_blank">View Map</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="each-address">
                                                    <h6>Jacksonville</h6>
                                                    <p><a href="https://goo.gl/maps/1jUStczSNgX5ryF3A" target="_blank">8384 Baymeadows Rd, Suite 3
                                                        Jacksonville, FL 32256</a></p>
                                                    <a href="https://goo.gl/maps/1jUStczSNgX5ryF3A" target="_blank">View Map</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="each-address">
                                                    <h6>Deland</h6>
                                                    <p><a href="https://goo.gl/maps/nTdmGrbmkskxPKrL6" target="_blank">120 South Woodland Blvd
                                                        Deland, FL 32720</a></p>
                                                    <a href="https://goo.gl/maps/nTdmGrbmkskxPKrL6" target="_blank">View Map</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="each-address">
                                                    <h6>Melbourne</h6>
                                                    <p><a href="https://goo.gl/maps/P3ATsE4ZoSLYr2KAA" target="_blank">1964 Dairy Road
                                                        West Melbourne, Fl 32904</a></p>
                                                    <a href="https://goo.gl/maps/P3ATsE4ZoSLYr2KAA" target="_blank">View Map</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="each-address">
                                                    <h6>Grant</h6>
                                                    <p><a href="https://goo.gl/maps/1gy9LY7E6Mr5r4S28" target="_blank">531 West Grant Street
                                                        Orlando, FL 32805</a></p>
                                                    <a href="https://goo.gl/maps/1gy9LY7E6Mr5r4S28" target="_blank">View Map</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="each-address">
                                                    <h6>Tampa </h6>
                                                    <p><a href="https://goo.gl/maps/BjTdVBrRmoCVCcWp7" target="_blank">100 S. Ashley Dr. Suite 600
                                                        Tampa, FL 33602</a></p>
                                                    <a href="https://goo.gl/maps/BjTdVBrRmoCVCcWp7" target="_blank">View Map</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="footer-content-bottom d-block d-lg-flex align-items-center justify-content-between">
                            <div className="footer-content-bottom-left">
                                <p>©{new Date().getFullYear()} AD Solutions. All Rights Reserved.</p>
                            </div>
                            <div className="footer-content-bottom-middle">
                                <ul>
                                    <li id="menu-item-84" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-84"><a rel="privacy-policy" href="https://myadsusa.com/privacy-policy/">Privacy Policy / Terms and Conditions</a></li>
                                    <li id="menu-item-85" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-85"><a href="https://myadsusa.com/sitemap/">Site Map</a></li>
                                    <li id="menu-item-86" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-86"><a href="https://myadsusa.com/accessibility-statement/">Accessibility Statement</a></li>
                                </ul>
                            </div>

                            <div className="footer-content-bottom-right d-flex align-items-center">
                                <p>Website Marketing &amp; Design by <strong><a href="https://dlmconversion.com/">DLM</a></strong></p>

                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        </div>
    );
}
export default React.memo(Footer);