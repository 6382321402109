
import {errorToast, successToast} from "../../../common/toasts/toastMessages";
import {UnlockAccountProps} from "../interfaces/viewAccountsInterfaces";

export function useUnlockAccount(props: UnlockAccountProps, windowWidth: number) {

    const handleUnlockAccount = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/account/unlock-account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`, // Add Bearer token header
                },
                body: JSON.stringify({emailAddress: props.emailAddress}),
            });

            const data = await response.json();
            if(response.ok) {
                successToast(data.message, windowWidth);
            }
            else {
                errorToast(data.message, windowWidth);
            }

        } catch (error) {
            errorToast('An error occurred when unlocking account. Please try again.', windowWidth);
        }
    }

    return {handleUnlockAccount};
}