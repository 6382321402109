import { ColDef } from "ag-grid-community";
import LockedStatusButton from "../LockedStatusField";

import {LockAndUnlockEmployeeStatusButtonProps} from "./interfaces/viewEmployeeAccountsInterface";
import UnlockAndLockEmployeeStatusUpdateButton from "./UnlockAndLockEmployeeStatusUpdateButton";

export const employeeAccountCols: ColDef[] = [
    {field: 'emailAddress', headerName: 'Email', sortable: true, filter: true,
        minWidth: 380, maxWidth: 420, cellStyle: {overflow: "hidden", whiteSpace: "nowrap"}},
    {field: 'roleDisplayName', headerName: 'Role', sortable: true, filter: true,
        minWidth: 240, maxWidth: 380, cellStyle: {overflow: "hidden", whiteSpace: "nowrap"}},
    {field: 'accountLocked', headerName: 'Locked',
        cellRenderer: LockedStatusButton,
        cellRendererParams: (params: { data: LockAndUnlockEmployeeStatusButtonProps; }) => ({
            accountLocked: params.data.accountLocked,
        }) ,sortable: true, filter: false, minWidth: 140, maxWidth: 140, cellStyle: {overflow: "hidden", whiteSpace: "nowrap"} },
    {field: 'accountLocked', headerName: '',
        cellRenderer: UnlockAndLockEmployeeStatusUpdateButton,
        cellRendererParams: (params: { data: LockAndUnlockEmployeeStatusButtonProps; }) => ({
            accountLocked: params.data.accountLocked,
            emailAddress: params.data.emailAddress,
            roleName: params.data.roleName
        }) ,sortable: false, filter: false, minWidth: 130, cellStyle: {overflow: "hidden", whiteSpace: "nowrap"} }
    ];

export const defaultEmployeeAccountsColDef:ColDef = {
    flex: 1,
    resizable: false,
    rowDrag: false,
    autoHeight: true,
    suppressMovable: true,
    suppressHeaderMenuButton: false,
    suppressHeaderContextMenu: false,
}