import React from "react";
import ToolsAndCustomerSidePanel from "./ToolsAndCustomerSidePanel";
import PrintContract from "./printContract/PrintContract";
import "../resources/MainLayout.css"
import PrintSSEComponent from "./printSuppliesServiceEquipment/SuppliesServiceEquipment";
import TotalDueJumbotron from "./jumbotron/TotalDueJumbotron";
import "../resources/MainLayout.css";
import "./stylesheets/homepage.css"
import PayAndDownloadCurrentInvoiceButtonGroup from "../common/PayAndDownloadCurrentInvoiceButtonGroup";
import {useToastAutoDismiss} from "../common/hooks/useToastAutoDismiss";
import useWindowWidth, {LG_SCREEN_WIDTH_PX} from "../common/hooks/useWindowWidth";


const HomePage: React.FC = () => {
    document.title = "Pay My Bill | Home | AD Solutions";
    useToastAutoDismiss();
    const windowWidth = useWindowWidth();

    return (
        <div className="container-xl" style={windowWidth > LG_SCREEN_WIDTH_PX ? {paddingTop: '9rem', paddingBottom: '1.6rem', minHeight: '80vh'} : {paddingTop: '0rem'}}>
            <div className="d-flex justify-content-center p-0 px-3 pb-3 row">
                <ToolsAndCustomerSidePanel />

                    <div className="col-12 col-xl-10 col-xxl-9">
                        <div className="row mb-4">
                            <TotalDueJumbotron />
                        </div>
                        <PayAndDownloadCurrentInvoiceButtonGroup />

                        <div className="row mb-4">
                            <PrintContract />
                        </div>
                        <div className="row mb-4">
                            <PrintSSEComponent />
                        </div>
                    </div>
                </div>
            </div>

    );
};

export default HomePage;
