import React from "react";
import {AgGridReact} from "ag-grid-react";


import {useEmployeeAccountStore} from "./store/employeeAccountStore";
import {useInitializeEmployeeAccountStore} from "./hooks/useInitializeEmployeeAccountStore";
import {defaultEmployeeAccountsColDef, employeeAccountCols} from "./employeeAccountColumnDefinitions";
import InternalSidePanel from "../../homepage/InternalSidePanel";
import useWindowWidth, {LG_SCREEN_WIDTH_PX} from "../../../common/hooks/useWindowWidth";
import {useAGGridLoadingHandler} from "../../../common/hooks/useAGGridLoadingHandler";
import Loading from "../../../common/Loading";
import '../../../common/ag-grid-theme/ag-grid-theme-builder.css';
import './stylesheets/view-employee-accounts.css'

function ViewEmployeeAccounts() {
    const windowWidth = useWindowWidth();
    useInitializeEmployeeAccountStore(windowWidth);
    const accounts = useEmployeeAccountStore(state => state.employeeAccounts);
    const [filterText, setFilterText] = React.useState('');
    const {onGridReady} = useAGGridLoadingHandler(accounts);
    return (
        <>
            <div className="container-xl" style={windowWidth > LG_SCREEN_WIDTH_PX ? {paddingTop: '9rem', paddingBottom: '1.6rem', minHeight: '40vh'} : {paddingTop: '0rem'}}>
                <div className="d-flex justify-content-center p-0 px-3 pb-3 row">
                    <InternalSidePanel/>
                    <div className="col-12 col-xl-9 p-0 m-0" style={{border: '2px solid #E6E7E8', backgroundColor: 'white', borderRadius: '12px'}}>
                            <div className="px-4 pt-3 m-0 pb-1" style={{width: '100%'}}>
                                <div className="row">
                                    <div className="col-12 col-lg-6 d-flex align-items-center">
                                        <h3>Employee Accounts</h3>
                                    </div>
                                    <div className="col-12 col-xl-6 pb-3 d-flex align-items-center">
                                        <input
                                            type="text"
                                            className="form-control pt-2 pb-2"
                                            placeholder="Search for ..."
                                            onChange={(e) => setFilterText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <AgGridReact
                                    onGridReady={onGridReady}
                                    rowData={accounts}
                                    columnDefs={employeeAccountCols}
                                    defaultColDef={defaultEmployeeAccountsColDef}
                                    pagination={true}
                                    paginationPageSize={10}
                                    enableCellTextSelection={true}
                                    containerStyle={{fontWeight: 'lighter'}}
                                    headerHeight={60}
                                    suppressRowHoverHighlight={true}
                                    domLayout={'autoHeight'}
                                    loadingOverlayComponent={Loading}
                                    quickFilterText={filterText}
                                    noRowsOverlayComponent={() => (<>No accounts are available to list at this time.</>)}
                                />
                            </div>
                        </div>
                    </div>
            </div>
        </>
    );
}

export default React.memo(ViewEmployeeAccounts)