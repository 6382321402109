import {errorToast} from "../../../common/toasts/toastMessages";
import {useAuthStore} from "../../../auth/store/useAuthStore";
import {useEffect} from "react";

export function useFetchRoles(windowWidth: number) {

    const {setRoles} = useAuthStore((state) => ({
        setRoles: state.setRoles,
    }));

    useEffect(() => {
        const getRoles = async() => {
            try {
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/roles/list', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')
                    }
                });

                const data = await response.json();

                if(!response.ok) {
                    errorToast(data.message, windowWidth);
                } else {
                    setRoles(data);
                }
            } catch (error) {
                errorToast('An error occurred when getting roles. Please try again.', windowWidth);
            }
        }

        getRoles();
    },[]);
}