import {Invoice} from "../interfaces/contractInterfaces";

export async function fetchMeterReadings() {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/meter-readings/list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
        });
        if (response.status === 200) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
    }
}

export async function fetchInvoices():Promise<Invoice[]> {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/invoices/list/contracts`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch invoices')
        }

        return await response.json();

    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function fetchTaxes() {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/taxes/list/contract`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
        });

        if (response.status === 200) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
    }
}

export async function fetchEquipment() {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/equipment/contract/list', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
        });

        if (response.status === 200) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
    }
}

export async function fetchContracts() {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/contracts/list', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
        });

        if (response.status === 200) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
    }
}