import {AutopayDetails} from "../../payment/interface/paymentInterfaces";

export const dueDate = (autopayDetails:AutopayDetails):Date => {
    const dueDate = new Date();
    dueDate.setDate(autopayDetails.billingDayOfMonth);
    return dueDate;
}

export const autopayScheduledOn = (autopayDetails:AutopayDetails): Date => {
    let date = createDateWithSpecifiedDay(autopayDetails.billingDayOfMonth);

    if(!isDayLessOrEqualToAutopayDay(date, new Date().getDate())){
        date = incrementMonth(date);
    }

    return date;
}

const isDayLessOrEqualToAutopayDay = (autopayDate: Date, dayOfMonth: number) : boolean => {
    return autopayDate.getDate() >= dayOfMonth;
}

const createDateWithSpecifiedDay = (dayOfMonth: number): Date => {
    const date = new Date();
    date.setDate(dayOfMonth);
    return date;
}

const incrementMonth = (date: Date): Date => {
    date.setMonth(date.getMonth() + 1);
    return date;
}
