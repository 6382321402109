

export function titleCase(str: string): string {
    let updatedStr = "";
    try {
        updatedStr = str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    } catch(error:any) {
        console.error(`An error occurred ${error.message}`)
    }

    return updatedStr;
}

export const currencyFormatterUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const numberFormatterWithCommas = new Intl.NumberFormat('en-US', {
    style: 'decimal',
});

export const currentFormatedDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
});

export const formattedSlashedDate = (date: Date) => {
    return date.toLocaleDateString('en-US', {
        dateStyle:'medium'
    });
}

export const getCookieValueAsNumber = (cookieName: string) => {
    const match = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));
    return match ? Number(match[2]) : null;
}
export const cookieExpirationDate = () => {
    let date = new Date();
    date.setDate(date.getDate() + 4);
    return date.toUTCString();
}

export const monthDict:Dictionary = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December'
}