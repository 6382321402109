import React, {lazy, Suspense} from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes, useLocation } from 'react-router-dom';
import MainLayout from "./common/layout/MainLayout";
import AlternativeLayout from "./common/layout/AlternativeLayout";
import Loading from "./common/Loading";
import useWindowWidth from "./common/hooks/useWindowWidth";
import {useIsAuthenticated} from "./auth/hooks/useIsAuthenticated";
import InternalRoutes from "./InternalRoutes";
import CustomerRoutes from "./CustomerRoutes";
import {useAuthStore} from "./auth/store/useAuthStore";

const LazyLogin = lazy(() => import('./auth/Login'));
const LazyForgotPassword = lazy(() => import('./passwordReset/ForgotPassword'));

export default function App() {
    const windowWidth = useWindowWidth();
    const isAuth = useIsAuthenticated(windowWidth);
    const permittedNotAuthenticatedRoutes = ["/forgot-password"];
    const {permissions} = useAuthStore((state) => ({
        permissions: state.permissions
    }))

    const AuthRoutes = () => {
        const location = useLocation();

        if (permittedNotAuthenticatedRoutes.includes(location.pathname)) {
            return null;
        }
        return <Navigate replace to="/login" />;
    }

    if(isAuth === null) {
        return <Loading />;
    }

    return (
        <Router>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/forgot-password" element={<AlternativeLayout component={<LazyForgotPassword />} />} />
                    <Route path="/login" element={<MainLayout component={<LazyLogin/>} />} />
                </Routes>
                {isAuth ? (
                    <>
                        {permissions.has('VIEW_EMPLOYEE_HOMEPAGE') && (
                            <InternalRoutes />
                        )}
                        {permissions.has('VIEW_CUSTOMER_HOMEPAGE') && (
                            <CustomerRoutes />
                        )}
                    </>
                ) : (<AuthRoutes />)}
            </Suspense>
        </Router>
    );
}
