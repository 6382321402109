import {useFetchEmployeeAccounts} from "./useFetchEmployeeAccounts";
import {useEffect} from "react";

export function useInitializeEmployeeAccountStore(windowWidth: number) {
    const getAccounts = useFetchEmployeeAccounts(windowWidth);

    useEffect(() => {
        const fetchAccounts = async() => {
            await getAccounts.fetchAccounts();
        }
        fetchAccounts();
    },[]);
}