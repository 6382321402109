import { GlobalContext} from "../../globalContext/Context";
import {currencyFormatterUSD} from "../../common/miscFunctions";
import {autopayScheduledOn, dueDate} from "./jumbotronFunctions";

export default function TotalDueJumbotron(){
    const {chargeTotal, autopayDetails} = GlobalContext();

    return(
        <div className="payHeader">
            <h4 style={{ fontSize: '2.2rem', paddingBottom: '.8rem', fontWeight:'lighter'}}> Invoice Total Due </h4>
            <h4 style={{ fontSize: '3rem', paddingBottom: '1.5rem', fontWeight:'lighter'}}> {currencyFormatterUSD.format(Number(chargeTotal))}</h4>

            {chargeTotal !== 0 && autopayDetails.autopayEnabled && (
                <h4 style={{fontSize: '18px'}}> Autopay is scheduled on {autopayScheduledOn(autopayDetails).toLocaleDateString()}.</h4>
            )}

            {chargeTotal !== 0 && !autopayDetails.autopayEnabled && (
                <h4 style={{fontSize: '18px'}}> Payment due on {dueDate(autopayDetails).toLocaleDateString()}.</h4>
            )}
            {chargeTotal === 0 && (
                <h4 style={{fontSize: '18px'}}> No Payment Due.</h4>
            )}
        </div>
    );

}

