import {useEffect, useState} from "react";
import {GridApi} from "@ag-grid-community/core";

export function useAGGridLoadingHandler(rowData: any[] | undefined) {
    const [gridApi, setGridApi] = useState<GridApi | null>(null);

    const onGridReady = (props: any) => {
        setGridApi(props.api);
    };

    useEffect(() => {
        if(gridApi) {
            if(rowData === undefined) {
                gridApi.showLoadingOverlay();
            } else if (rowData.length === 0) {
                gridApi.showNoRowsOverlay();
            } else {
                gridApi.hideOverlay();
            }
        }
    }, [gridApi, rowData])

    return {onGridReady}
}