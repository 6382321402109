import React from "react";
import {LockAndUnlockStatusButtonProps} from "./interfaces/viewAccountsInterfaces";

function LockedStatusField(props: LockAndUnlockStatusButtonProps) {
    return (
        <div className="d-flex align-items-center">
            {props.accountLocked ? (
                <>Yes</>
            ) : (
                <>No</>
            )}
        </div>
    );
}

export default React.memo(LockedStatusField);