import { Link } from "react-router-dom";
import React from "react";
import {getCookieValueAsNumber} from "../common/miscFunctions";

function Header() {

    const lastPaid = getCookieValueAsNumber("lastPaid") || 0;

    return (
        <div style={{ fontWeight: '400', zIndex: 5}}>
            <button type="button" aria-controls="rmp-container-366" aria-label="Menu Trigger" id="rmp_menu_trigger-366"
                className="rmp_menu_trigger rmp-menu-trigger-boring">
                <span className="rmp-trigger-box">
                    <span className="responsive-menu-pro-inner"></span>
                </span>
            </button>
            <header className="header-area fixed_header stky mb-5">
                <div className="top-header">
                    <div className="container">
                        <div
                            className="top-header-content-wrapper d-flex justify-content-between align-items-center mt-0 mb-0">
                            <div className="logo-area">

                                <a href="https://myadsusa.com"><img
                                    src="https://myadsusa.com/wp-content/uploads/2022/11/ADSolutions_Logo_RGB.svg"
                                    alt="" /></a>
                            </div>
                            <div className="top-area-right-content d-flex align-items-center">
                                <div className="top-header-menu">
                                    <ul>
                                        {lastPaid === 0 ? (
                                            <Link to="/pay-my-bill">
                                                <li id="menu-item-2411"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2411">
                                                    <a>Pay My Bill</a>
                                                </li>
                                            </Link>
                                        ) : (
                                            <Link to="" style={{color: 'grey'}}>
                                                <li id="menu-item-2411" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2411">
                                                    <a>Pay My Bill</a>
                                                </li>
                                            </Link>
                                        )}
                                        <li id="menu-item-2413"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2413">
                                            <a href="https://myadsusa.com/order-supply-form/">Order Supply Form</a></li>
                                    </ul>
                                </div>
                                <div className="search-icon-area open-search-box">
                                    <span><img
                                        src="https://myadsusa.com/wp-content/themes/twentytwentyone-child/assets/images/Icon awesome-search.svg"
                                        width="13" height="13" alt="" /></span>Search
                                </div>
                                <div className="phone-number">
                                    <a href="tel:866-691-6347">866-691-6347</a>
                                </div>
                                <div className="header-menu-btns">
                                    <div className="Get-started-btn top-request-btn">
                                        <a href="https://myadsusa.com/request-a-quote/">Request a Quote</a>
                                    </div>
                                </div>
                                <div className="mobile-icon d-lg-none" style={{ opacity: '0' }}>
                                    <a href="" style={{ paddingRight: '10px' }}><img
                                        src="https://myadsusa.com/wp-content/themes/twentytwentyone-child/assets/images/Group 36.png"
                                        width="41" height="22" alt="" style={{ border: '0' }} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-menu-area">
                    <div className="container">
                        <div className="main-menu">
                            <ul>
                                <li id="menu-item-368"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-368">
                                    <a href="https://myadsusa.com/solutions-overview/">Solutions</a>
                                    <ul className="sub-menu">
                                        <li id="menu-item-269"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-269">
                                            <a href="https://myadsusa.com/display-solutions/">Display Solutions</a></li>
                                        <li id="menu-item-249"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-249">
                                            <a href="https://myadsusa.com/device-solutions/">Device Solutions</a></li>
                                        <li id="menu-item-248"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-248">
                                            <a href="https://myadsusa.com/it-solutions/">IT Solutions</a></li>
                                        <li id="menu-item-247"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-247">
                                            <a href="https://myadsusa.com/print-solutions/">Print Solutions</a></li>
                                        <li id="menu-item-400"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-400">
                                            <a href="https://myadsusa.com/software-solutions/">Software Solutions</a>
                                        </li>
                                        <li id="menu-item-245"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-245">
                                            <a href="https://myadsusa.com/voip-solutions/">Voice Solutions</a></li>
                                    </ul>
                                </li>
                                <li id="menu-item-369"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-369">
                                    <a href="https://myadsusa.com/technologies-overview/">Technology</a>
                                    <ul className="sub-menu">
                                        <li id="menu-item-3757"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3757">
                                            <a href="https://myadsusa.com/ads-managed-print-solutions/">Copiers &amp; Printers</a>
                                        </li>
                                        <li id="menu-item-3759"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3759">
                                            <a href="https://myadsusa.com/ads-voip-landing-page/">VOIP Technologies</a>
                                        </li>
                                        <li id="menu-item-3758"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3758">
                                            <a href="https://myadsusa.com/ads-ids-video-walls-landing-page/">Video
                                                Walls</a></li>
                                        <li id="menu-item-3760"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3760">
                                            <a href="https://myadsusa.com/interactive-displays-aquos-board/">Aquos Smart
                                                Board</a></li>
                                        <li id="menu-item-407"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-407">
                                            <a href="https://myadsusa.com/device-solutions/dynabook-laptops/">Dynabook
                                                Laptops</a></li>
                                        <li id="menu-item-408"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-408">
                                            <a href="https://myadsusa.com/device-solutions/hp-chromebooks/">HP
                                                Chromebooks</a></li>
                                        <li id="menu-item-409"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-409">
                                            <a href="https://myadsusa.com/device-solutions/lenovo-tablets/">Lenovo
                                                Tablets</a></li>
                                        <li id="menu-item-410"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-410">
                                            <a href="https://myadsusa.com/device-solutions/mini-pcs/">Mini PCs</a></li>
                                        <li id="menu-item-4073"
                                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4073">
                                            <a href="https://myadsusa.com/products-cat/mfp-printer-models/">Copiers &amp; Printers
                                                Catalog</a></li>
                                        <li id="menu-item-3500"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3500">
                                            <a href="https://myadsusa.com/print-solutions/hp-printers-scanners/">HP
                                                Printers &amp; Scanners</a></li>
                                    </ul>
                                </li>
                                <li id="menu-item-370"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-370">
                                    <a href="https://myadsusa.com/industries-overview/">Industries</a>
                                    <ul className="sub-menu">
                                        <li id="menu-item-398"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-398">
                                            <a href="https://myadsusa.com/corporate/">Corporate Office Solutions</a>
                                        </li>
                                        <li id="menu-item-397"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-397">
                                            <a href="https://myadsusa.com/small-business/">Small Business Solutions</a>
                                        </li>
                                        <li id="menu-item-352"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-352">
                                            <a href="https://myadsusa.com/healthcare/">Healthcare Business Solutions</a>
                                        </li>
                                        <li id="menu-item-396"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-396">
                                            <a href="https://myadsusa.com/education/">Education</a></li>
                                        <li id="menu-item-395"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-395">
                                            <a href="https://myadsusa.com/manufacturing/">Manufacturing Office
                                                Solutions</a></li>
                                        <li id="menu-item-504"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-504">
                                            <a href="https://myadsusa.com/legal/">Legal</a></li>
                                        <li id="menu-item-506"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-506">
                                            <a href="https://myadsusa.com/non-profits/">Non-Profits</a></li>
                                    </ul>
                                </li>
                                <li id="menu-item-371"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-371">
                                    <a href="/customer-tools/">Customer Tools</a>
                                    <ul className="sub-menu">
                                        <li id="menu-item-388"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-388">
                                            <a href="/payment">Pay My Bill</a></li>
                                        <li id="menu-item-387"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-387">
                                            <a href="https://myadsusa.com/service-request-form/">Service Request
                                                Form</a></li>
                                        <li id="menu-item-2414"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2414">
                                            <a href="https://myadsusa.com/order-supply-form/">Order Supply Form</a></li>
                                        <li id="menu-item-355"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-355">
                                            <a href="https://myadsusa.com/meter-read-submission/">Meter Read
                                                Submission</a></li>
                                        <li id="menu-item-3101"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3101">
                                            <a href="https://myadsusa.com/remote-support-session/">Remote Support
                                                Session</a></li>
                                        <li id="menu-item-4019"
                                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4019">
                                            <a href="/products-cat/mfp-printer-models/">Copiers &amp; Printers
                                                Catalog</a></li>
                                    </ul>
                                </li>
                                <li id="menu-item-372"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-372">
                                    <a href="https://myadsusa.com/company-overview-2/">Company</a>
                                    <ul className="sub-menu">
                                        <li id="menu-item-378"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-378">
                                            <a href="https://myadsusa.com/about-us/">About Us</a></li>
                                        <li id="menu-item-377"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-377">
                                            <a href="https://myadsusa.com/our-promise/">Our Promise</a></li>
                                        <li id="menu-item-376"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-376">
                                            <a href="https://myadsusa.com/awards/">Awards</a></li>
                                        <li id="menu-item-380"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-380">
                                            <a href="https://myadsusa.com/blog/">Our Blog</a></li>
                                    </ul>
                                </li>
                                <li id="menu-item-373"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-373">
                                    <a href="/contact-us/">Contact</a>
                                    <ul className="sub-menu">
                                        <li id="menu-item-354"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-354">
                                            <a href="https://myadsusa.com/location/">Location Information</a></li>
                                        <li id="menu-item-3991"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3991">
                                            <a href="https://myadsusa.com/careers-2/">Careers</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default React.memo(Header);