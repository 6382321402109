import React, { createContext, useContext, useState, useEffect } from 'react';
import {
    Equipment as SSEEquipment,
    Invoice,
    Invoice as SSEInvoice, Location, MiscCharges, SalesOrder, Service, Supplies, Taxes as SSETaxes
} from "../homepage/printSuppliesServiceEquipment/interfaces/sseInterfaces";
import {
    GetEquipment as getSSEEquipment,
    GetInvoices as getSSEInvoices, GetLocation, GetMaterials, GetMiscCharges, GetSalesOrders, GetService, GetSupplies,
    GetTaxes as fetchSSETaxes
} from "../homepage/printSuppliesServiceEquipment/fetches/sseFetches";
import {
    getAutopayDetails,
    GetInvoices as DueInvoices,
    getSavedBankAccounts,
    getSavedCards
} from "../payment/fetches/paymentFetches";
import {
    fetchContracts, fetchEquipment as fetchContractEquipment, fetchMeterReadings,
    fetchInvoices as ContractInvoices,
    fetchTaxes as fetchContractTaxes
} from "../homepage/printContract/fetches/contractFetches";
import {
    Contracts, Equipment as ContractEquipment, MeterReading,
    Invoice as ContractInv,
    Taxes as ContractTaxes
} from "../homepage/printContract/interfaces/contractInterfaces";
import {AutopayDetails, SavedBankAccount, SavedCardInt} from "../payment/interface/paymentInterfaces";
import {ChargeTotalContextProps, ChargeTotalContextValue} from "./interfaces/contextInterface";
import {getCookieValueAsNumber} from "../common/miscFunctions";

const ChargeTotalContext = createContext<ChargeTotalContextValue | undefined>(undefined);

export default function ContextProvider(props: ChargeTotalContextProps):React.JSX.Element {

    const [chargeTotal, setChargeTotal] = useState<number|null>(null);
    const [contractCharge, setContractCharge] = useState<number>(0);
    const [servicesCharge, setServicesCharge] = useState<number>(0);
    const [conIds, setConIds] = useState<string[]>([]);
    const [dueInvoices, setDueInvoices] = useState<Invoice[]>([]);
    const [sseInvoices, setSSEInvoices] = useState<SSEInvoice[]>([]);
    const [contractInvoices, setContractInvoices] = useState<ContractInv[]>([]);
    const [contracts, setContracts] = useState<Contracts[]>([]);
    const [savedCards, setSavedCards] = useState<SavedCardInt[]>([]);
    const [savedBankAccounts, setSavedBankAccounts] = useState<SavedBankAccount[]>([]);
    const [contractTaxes, setContractTaxes] = useState<ContractTaxes[]>([]);
    const [sseTaxes, setSSETaxes] = useState<SSETaxes[]>([]);
    const [contractEquipment, setContractEquipment] = useState<ContractEquipment[]>([]);
    const [sseEquipment, setSSEEquipment] = useState<SSEEquipment[]>([]);
    const [meterReadings, setMeterReadings] = useState<MeterReading[]>([]);
    const [supplies, setSupplies] = useState<Supplies[]>([]);
    const [materials, setMaterials] = useState<Supplies[]>([]);
    const [serviceCalls, setServiceCalls] = useState<Service[]>([]);
    const [salesOrders, setSalesOrders] = useState<SalesOrder[]>([]);
    const [miscCharges, setMiscCharges] = useState<MiscCharges[]>([])
    const [locations, setLocations] = useState<Location[]>([]);
    const [autopayDetails, setAutopayDetails] = useState<AutopayDetails>({} as AutopayDetails);
    const [showAddPaymentMethod, setShowAddPaymentMethod] = useState<boolean>(false);
    const [cardsUpdateTrigger, setCardsUpdateTrigger] = useState<boolean>(false);
    const [bankAccountsUpdateTrigger, setBankAccountsUpdateTrigger] = useState<boolean>(false);
    const [autopaySettingsUpdateTrigger, setAutopaySettingsUpdateTrigger] = useState<boolean>(false);
    const [lastPaid, setLastPaid] = useState(getCookieValueAsNumber("lastPaid") || 0);

    useEffect(() => {
        Promise.all([
            getSSEInvoices(),
            ContractInvoices(),
            DueInvoices(),
            fetchContracts(),
            fetchContractTaxes(),
            fetchSSETaxes(),
            fetchContractEquipment(),
            fetchMeterReadings(),
            getSSEEquipment(),
            GetSupplies(),
            GetMaterials(),
            GetService(),
            GetMiscCharges(),
            GetLocation(),
            GetSalesOrders()

        ]).then(([sseInvoices, contractInvoices, dueInv, contracts, contractTaxes, sseTaxes,
                     contractEquipment, meterReadings, sseEquipment, supplies,
                     materials, service, miscCharges, locations, salesOrders]) => {
            setSSEInvoices(lastPaid !== 0 ? [] : sseInvoices);
            setContractInvoices(lastPaid !== 0 ? [] : contractInvoices);
            setDueInvoices(lastPaid !== 0 ? [] : dueInv);
            setContracts(lastPaid !== 0 ? [] : contracts);
            setContractTaxes(lastPaid !== 0 ? [] : contractTaxes);
            setSSETaxes(lastPaid !== 0 ? [] : sseTaxes);
            setContractEquipment(lastPaid !== 0 ? [] : contractEquipment);
            setMeterReadings(lastPaid !== 0 ? [] : meterReadings);
            setSSEEquipment(lastPaid !== 0 ? [] : sseEquipment);
            setSupplies(lastPaid !== 0 ? [] : supplies);
            setMaterials(lastPaid !== 0 ? [] : materials);
            setServiceCalls(lastPaid !== 0 ? [] : service);
            setMiscCharges(lastPaid !== 0 ? [] : miscCharges);
            setLocations(locations);
            setSalesOrders(lastPaid !== 0 ? [] : salesOrders);
        });

    }, [lastPaid]);

    useEffect(() => {
        const contractCharge = contractInvoices.reduce((sum:number , invoice) => sum + Number(invoice.LeftToPay), 0);
        setContractCharge(contractCharge);
    }, [contractInvoices]);

    useEffect(() => {
        const serviceCharge = sseInvoices.reduce((sum:number , invoice) => sum + Number(invoice.LeftToPay), 0);
        setServicesCharge(serviceCharge);
    }, [sseInvoices]);

    useEffect(() => {
        setConIds(contracts.map(contract => contract.ContractNumber));
    }, [contracts]);

    useEffect(() => {
        const total = dueInvoices.reduce((sum:number , invoice) => sum + Number(invoice.LeftToPay), 0);
        if(chargeTotal !== null && chargeTotal > 0) {
            setChargeTotal(total);
        } else if(total > 0) {
            setChargeTotal(total);
        }
    }, [dueInvoices]);

    const triggerUpdateAutopayDetails = () => {
        setAutopaySettingsUpdateTrigger(!autopaySettingsUpdateTrigger);
    }

    const triggerUpdateSavedCards = () => {
        setCardsUpdateTrigger(!cardsUpdateTrigger);
    }

    const triggerUpdateSavedBankAccounts = () => {
        setBankAccountsUpdateTrigger(!bankAccountsUpdateTrigger);
    }

    const updateShowAddPaymentMethod = () => {
        setShowAddPaymentMethod(!showAddPaymentMethod);
    }

    useEffect(() => {
        getSavedCards().then(cards => setSavedCards(cards));
    }, [cardsUpdateTrigger]);

    useEffect(() => {
        getSavedBankAccounts().then(bankAccounts => setSavedBankAccounts(bankAccounts));
    }, [bankAccountsUpdateTrigger]);

    useEffect(() => {
        getAutopayDetails().then(details => setAutopayDetails(details));
    }, [autopaySettingsUpdateTrigger]);

    return (

        <ChargeTotalContext.Provider value={{
            chargeTotal,
            contractCharge,
            servicesCharge,
            conIds,
            savedCards,
            savedBankAccounts,
            contracts,
            dueInvoices,
            sseInvoices,
            contractInvoices,
            contractTaxes,
            sseTaxes,
            contractEquipment,
            sseEquipment,
            meterReadings,
            supplies,
            materials,
            serviceCalls,
            miscCharges,
            locations,
            autopayDetails,
            salesOrders,
            lastPaid,
            setLastPaid,
            triggerUpdateSavedCards,
            triggerUpdateSavedBankAccounts,
            updateShowAddPaymentMethod,
            triggerUpdateAutopayDetails,
            showAddPaymentMethod
        }}>

            {contractInvoices && savedCards && savedBankAccounts && props.children}

        </ChargeTotalContext.Provider>
    );
}

export const GlobalContext = (): ChargeTotalContextValue => {
    const context = useContext(ChargeTotalContext);

    if (!context) {
        throw new Error('useChargeTotal must be used within an ChargeTotalProvider');
    }

    return context;
};
