import React, {useState} from "react";
import AdminHomeSidePanel from "../homepage/InternalSidePanel";
import './stylesheets/create-account.css';
import useWindowWidth, {LG_SCREEN_WIDTH_PX} from "../../common/hooks/useWindowWidth";
import {useToastAutoDismiss} from "../../common/hooks/useToastAutoDismiss";
import {useCreateAccount} from "./hooks/useCreateAccount";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

function CreateCustomerAccount() {

    document.title = 'Create Account | AD Solutions'
    useToastAutoDismiss();
    const windowWidth = useWindowWidth();
    const createAccount = useCreateAccount();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const onSubmit = async(data: any) => {
        setIsSubmitting(true);
        await createAccount(data, windowWidth, reset,  '/api/account/create-customer');
        setIsSubmitting(false);
    }

    const schema = yup.object().shape({
        emailAddress: yup.string().email('Invalid email address').required('An email is required'),
        firstName: yup.string().trim().min(2,"First name must be at least two characters.").required('A first name is required'),
        lastName: yup.string().trim().min(2,"Last name must be at least two characters.").required('A last name is required'),
        companyPositionTitle: yup.string().min(3,"Job title must be at least three characters.").required('A job title is required.'),
        phoneNumber: yup.string().nullable(),
        billingDayOfMonth: yup.number().min(1, 'A number of at least one digit is required.'),
        eAutomateCustomerNumber: yup.string().trim(),
        connectwiseCustomerID: yup.string().trim(),
    });

    const { control, handleSubmit, formState: { errors }, reset , register} = useForm({
        resolver: yupResolver(schema)
    });

    return (
        <div className="container-xl" style={windowWidth > LG_SCREEN_WIDTH_PX ? {paddingTop: '9rem', paddingBottom: '1.6rem'} : {paddingTop: '0'}}>
            <div className="d-flex justify-content-center p-0 px-3 pb-3 row">
                <AdminHomeSidePanel/>
                <div className="col-12 col-xl-9" style={{backgroundColor: 'white', borderRadius: '12px', border: '2px solid #E6E7E8'}}>
                    <div className="p-4">
                        <h3 style={{fontSize: '2.5rem'}}>Create Customer Account</h3>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <h3 className="pt-3 mb-0" style={{fontSize: '1.4rem'}}>Customer Identification</h3>
                                <div className="col-12" title="An email address is required.">
                                    <label htmlFor="emailAddress" className="form-label labels">Email Address <span
                                        className="text-danger"
                                    >*</span></label>
                                    <Controller control={control} defaultValue={undefined} render={({field}) => (
                                        <input type="email" className="form-control" id="emailAddress" {...field}
                                               placeholder="user@myadsusa.com" required/>
                                    )} name="emailAddress"></Controller>
                                    {errors.emailAddress && <p className="text-danger mb-0 p-0" style={{fontWeight:'lighter'}}>{errors.emailAddress.message}</p>}
                                </div>
                            </div>
                            <hr style={{marginTop: '2.6rem', marginBottom: '2.4rem'}}/>
                            <div className="row">
                                <h3 className="mb-0" style={{fontSize: '1.4rem'}}>Personal Information</h3>
                                <div className="col-12 col-lg-6" title="A first name is required.">
                                    <label htmlFor="firstName" className="form-label labels">First Name <span
                                        className="text-danger"
                                    >*</span></label>
                                    <Controller control={control} defaultValue={undefined} render={({field}) => (
                                        <input type="text" className="form-control" id="firstName" {...field}
                                               placeholder="John" required/>
                                    )} name="firstName"></Controller>
                                    {errors.firstName && <p className="text-danger mb-0 p-0" style={{fontWeight:'lighter'}}>{errors.firstName.message}</p>}
                                </div>
                                <div className="col-12 col-lg-6" title="A last name is required.">
                                    <label htmlFor="lastName" className="form-label labels">Last Name <span
                                        className="text-danger"
                                    >*</span></label>
                                    <Controller control={control} defaultValue={undefined} render={({field}) => (
                                        <input type="text" className="form-control" id="lastName" {...field}
                                               placeholder="Doe" required/>
                                    )} name="lastName"></Controller>
                                    {errors.lastName && <p className="text-danger mb-0 p-0" style={{fontWeight:'lighter'}}>{errors.lastName.message}</p>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6" title="An optional field. Provides another way of contacting the customer.">
                                    <label htmlFor="phoneNumber" className="form-label labels">Phone Number</label>
                                    <input type="text" className="form-control" id="phoneNumber" defaultValue=""
                                           {...register('phoneNumber')}
                                           placeholder="(123) 456-7890"/>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <label htmlFor="companyPositionTitle" className="form-label labels">Job
                                        Title <span
                                            className="text-danger"
                                        >*</span></label>
                                    <Controller control={control} defaultValue={undefined} render={({field}) => (
                                        <input type="text" className="form-control" id="companyPositionTitle"
                                               {...field}
                                               placeholder="Accountant" required/>
                                    )} name="companyPositionTitle"></Controller>
                                    {errors.companyPositionTitle && <p className="text-danger mb-0 p-0" style={{fontWeight:'lighter'}}>{errors.companyPositionTitle.message}</p>}
                                </div>
                            </div>
                            <hr style={{marginTop: '2.6rem', marginBottom: '2.4rem'}}/>
                            <div className="row">
                                <h3 className="mb-0" style={{fontSize: '1.4rem'}}>Billing Information</h3>
                                <div className="col-12 col-lg-6" title="A billing day of month is required.">
                                    <label htmlFor="billingDayOfMonth" className="form-label labels">Day of
                                        Month <span className="text-danger"
                                        >*</span></label>
                                    <Controller control={control} defaultValue={1} render={({field}) => (
                                        <select className="form-control" {...field}
                                                id="billingDayOfMonth" required>
                                            <option key={1} value="1">1st</option>
                                            <option key={2} value="15">15th</option>
                                        </select>
                                    )} name="billingDayOfMonth"></Controller>
                                    {errors.billingDayOfMonth && <p className="text-danger mb-0 p-0" style={{fontWeight:'lighter'}}>{errors.billingDayOfMonth.message}</p>}
                                </div>
                            </div>
                            <hr style={{marginTop: '2.6rem', marginBottom: '2.4rem'}}/>
                            <div className="row">
                                <h3 className="mb-0" style={{fontSize: '1.4rem'}}>Account IDs <p className="pt-2 m-0"
                                                                                                 style={{fontWeight: 'lighter'}}>
                                    <i>At least one of these are required for billing purposes.</i></p></h3>
                                <div className="col-12 col-lg-6">
                                    <label htmlFor="eAutomateCustomerNumber" className="form-label labels">eAutomate
                                        Customer Number <span
                                            className="text-danger"
                                        >*</span></label>
                                    <input type="text" className="form-control" id="eAutomateCustomerNumber"
                                           {...register('eAutomateCustomerNumber')} defaultValue={undefined}
                                           placeholder="AD00" />
                                </div>

                                <div className="col-12 col-lg-6">
                                    <label htmlFor="connectwiseCustomerID" className="form-label labels">Connectwise
                                        Customer ID <span
                                            className="text-danger"
                                        >*</span></label>
                                    <input type="number" className="form-control" id="connectwiseCustomerID" defaultValue={undefined}
                                           {...register('connectwiseCustomerID')} min={0}
                                           placeholder="15" />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="submit" className="btn create-account-button mt-4" disabled={isSubmitting}>Create Account
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(CreateCustomerAccount);