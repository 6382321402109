import {useEmployeeAccountStore} from "../store/employeeAccountStore";
import {errorToast} from "../../../../common/toasts/toastMessages";

export function useFetchEmployeeAccounts(windowWidth: number) {
    const setAccounts = useEmployeeAccountStore(state => state.setEmployeeAccounts);

    const fetchAccounts = async() => {
        try {
            const response = await fetch (process.env.REACT_APP_API_BASE_URL + '/api/account/company/list', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
                }
            });

            const data = await response.json();

            if(response.ok) {
                setAccounts(data);
            } else {
                errorToast(data.message, windowWidth);
            }
        }
        catch (error) {
            errorToast('There was an issue getting account data. Please try again.', windowWidth);
        }
    }

    return {fetchAccounts};
}