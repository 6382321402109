import React, {useEffect} from "react";

export default function useGetRoute() {
    const [location, setLocation] = React.useState(window.location.pathname);

    useEffect(() => {
        const handleRouteChange = () => {
            setLocation(window.location.pathname);
        }

        handleRouteChange();

        window.addEventListener('popstate', handleRouteChange);
        return () => window.removeEventListener('popstate', handleRouteChange);
    }, []);

    return location;
}