import React from "react";
import {titleCase} from "../../common/miscFunctions";
import {SalesOrderProps} from "./interfaces/sseInterfaces";

function SalesOrders(props:SalesOrderProps) {
    return (
        <>
            {props.salesOrders.length === 0 ? <></> : (
            <div className="mb-0 p-0">
                <p className="caption-top text-dark mb-0"><b>Sales Orders</b></p>
                <div className="w-100" style={{overflowX: 'auto'}}>
                    <table className="table table-borderless table-responsive">
                        <thead className="justify-content-center">
                        <tr className="align-content-center">
                            <th className="text-start d-table-cell" style={{width: '12.5%'}}>Date</th>
                            <th className="text-start d-table-cell" style={{width: '12.5%'}}>SO Number</th>
                            <th className="d-none d-md-table-cell" style={{width: 'auto'}}>Description</th>
                            <th className="d-md-none d-table-cell" style={{width: '25%'}}>Desc</th>
                            <th className="d-none d-md-table-cell" style={{width: '15%'}}>Quantity</th>
                            <th className="d-md-none d-table-cell" style={{width: '15%'}}>Qty</th>
                            <th className="d-table-cell" style={{width: 'auto'}}>Price</th>
                            <th className="d-table-cell text-end" style={{width: '15%'}}>Charge</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.salesOrders.map(salesOrder => (
                            <tr>
                                <td className="d-table-cell"
                                    style={{width: '12.5%'}}>{new Date(salesOrder.date).toLocaleDateString()}</td>
                                <td className="d-table-cell d-md-table-cell"
                                    style={{width: '12.5%'}}>{salesOrder.salesOrderNumber}</td>
                                <td className="d-table-cell d-md-table-cell"
                                    style={{width: 'auto'}}>{titleCase(salesOrder.description)}</td>
                                <td className="d-table-cell"
                                    style={{width: '15%'}}>{Number(salesOrder.quantity).toFixed(2)}</td>
                                <td className="d-table-cell"
                                    style={{width: '15%'}}>{props.formatter.format(salesOrder.costPerItem)}</td>
                                <td className="text-end d-table-cell"
                                    style={{width: '15%'}}>{props.formatter.format(salesOrder.itemsTotal)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            )}
        </>
    )
}

export default React.memo(SalesOrders);